.frc__review-summary {
  background-color: var(--color-primary--Web-White);
  border: 1px solid var(--color-secondary--grey-1);
  display: flex;
  flex-wrap: wrap;
  padding: var(--base-line-height);
  justify-content: space-between;
  margin-bottom: calc(1.5 * var(--base-line-height));
  margin-top: calc(0.7 * var(--base-line-height));
}

.frc__review-summary__title {
  width: 100%;
  margin-bottom: 10px;
}

.frc__review-summary__box {
  width: 33%;
  background: var(--color-secondary--grey-5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: calc(1.5 * var(--base-line-height));
  flex-direction: column;
}
