.frc-transactions-table {
  margin-bottom: 0;
  min-width: 600px;
}

.frc-transactions-table__headers {
  background-color: var(--color-secondary--grey-3);
  color: var(--color-primary--Web-Black);
  font-size: var(--font-size-2);
  padding: 0.5rem 1rem;
}

.frc-transactions-table__item {
  padding: 0.5rem 1rem;
}

.frc-transactions-table__item:nth-child(odd) {
  background-color: var(--color-secondary--grey-5);
}

.frc-transactions-table__item:nth-child(even) {
  background-color: var(--color-primary--Web-White);
}

.frc-transactions-table-display {
  padding-top: 1rem;
}

.frc-transactions-table-display__span {
  padding-right: 0.5rem;
}

.frc__heading-spacer {
  padding: 1rem 1rem 0 1rem;
}

.c-download-box--icon.icon-pdf.frc__font-size--small:before {
  font-size: var(--font-size-3);
}

.frc__generic-button .frc__generic-button__span {
  line-height: 36px;
}
