.frc__side-summary-panel--wrapper {
  width: 100%;
  height: 100%;
  margin-right: calc(1.3 * var(--base-line-height));
  /* background-color: var(--color-primary--Web-White); */
  position: relative;
}

.frc__side-summary-panel {
  padding: calc(2 * var(--base-line-height)) var(--base-line-height);
  background-color: var(--color-secondary--grey-3);
  position: -webkit-sticky;
  position: sticky;
  top: calc(3.75 * var(--base-line-height));
}

.frc__shipment-type {
  margin-bottom: 0;
  text-transform: capitalize;
}

.frc__pickup-location {
  margin-bottom: 0;
  word-break: break-all;
}

.frc__shipment-details-p {
  margin-bottom: 0;
}

.frc__pickup-options {
  display: flex;
  justify-content: space-between;
}
