.frc__filter_title {
  color: var(--color-primary--DHL-Red);
}

@media screen and (max-width: 767px), print {
  .frc_from-date-margin {
    margin-right: calc(0.8 * var(--base-line-height));
  }
}

.frc__shipment-filter-component {
  margin-top: calc(3 * var(--base-line-height));
  background-color: var(--color-secondary--grey-4);
  padding: calc(1 * var(--base-line-height));
  box-sizing: border-box;
  position: relative;
}
