.frc__modal-view-edit-list {
  overflow: auto;
  width: 100%;
}

.frc__modal-max-width {
  max-height: calc(19 * var(--base-line-height));
}

.frc__modal-content {
  font-size: 14px;
  line-height: 16px;
  background-color: var(--color-primary--Web-White);
}

.frc__modal-padding {
  padding: calc(0.72 * var(--base-line-height));
}

.frc__modal-attribute-color {
  color: var(--color-secondary--grey-2);
}

.frc__modal--row div:last-child {
  padding-left: calc(0.5 * var(--base-line-height));
}

.frc__modal-content:nth-child(even) {
  background: #e8e8e8;
}

.frc__modal-view-edit-center {
  text-align: center;
  line-height: 1px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .frc__modal-view-edit-center {
    margin-bottom: calc(1.1 * var(--base-line-height));
    text-align: center;
    line-height: calc(1.6 * var(--base-line-height));
  }
}

.frc__modal-dialog-width {
  max-width: 35em;
}

.frc__modal-content:nth-child(even) {
  background-color: #f8f8f8;
}

.frc__delete-contact-msg {
  color: var(--color-primary--DHL-Red);
}

.frc__btn-margin-right {
  margin-right: calc(0.75 * var(--base-line-height));
}
