.frc__save-as-template-container {
  border: 1px solid var(--color-secondary--grey-2);
}

.frc__template-details p {
  margin-bottom: 0;
}

.frc__template-details p:last-child {
  margin-bottom: calc(0.5 * var(--base-line-height));
}

.frc__templatename-text {
  align-self: center;
  margin-top: 25px;
  text-align: center;
}

.frc__template-saved {
  margin-bottom: calc(0.2 * var(--base-line-height));
  margin-left: calc(0.5 * var(--base-line-height));
}

.frc__saved-thank-you-icon {
  margin-left: calc(0.3 * var(--base-line-height));
  padding-top: calc(0.2 * var(--base-line-height));
}

@media screen and (min-width: 768px) {
  .frc__save-btn-top {
    padding-top: calc(1.2 * var(--base-line-height));
  }
}

.frc__saved-btn-trim {
  margin: 0 calc(0.3 * var(--base-line-height)) 0 calc(0.3 * var(--base-line-height));
}
