.frc__textarea {
  padding: calc(0.5 * var(--base-line-height)) var(--base-line-height);
  color: var(--color-primary--Web-Black);
  font-size: var(--font-size-1);
  background: var(--color-primary--Web-White);
  line-height: calc(1.5 * var(--base-line-height));
  margin: 0;
  width: 100%;
  border: 1px solid var(--color-secondary--grey-2);
  /* margin-bottom: calc(0.2 * var(--base-line-height)); */
  border-radius: 0;
}

.frc__textarea.disabled {
  background: var(--color-secondary--grey-5);
  opacity: 0.8;
}

.frc__textarea--wrapper {
  display: block;
}

.frc__textarea--wrapper label {
  padding-bottom: calc(0.5 * var(--base-line-height));
}

.frc__textarea--small {
  height: calc(7 * var(--base-line-height));
}

.frc__textarea--tiny {
  height: calc(5 * var(--base-line-height));
}
