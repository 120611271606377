.frc__reset-password {
  background-color: var(--color-secondary--grey-5);
  padding: calc(2 * var(--base-line-height));
}

.frc__form--element-input--all-borders {
  padding: calc(0.5 * var(--base-line-height)) var(--base-line-height);
  color: var(--color-secondary--grey-1);
  font-size: var(--font-size-1);
  background: var(--color-primary--Web-White);
  line-height: calc(1.5 * var(--base-line-height));
  margin: 0;
  width: 100%;
  border: 1px solid var(--color-secondary--grey-2);
  margin-bottom: calc(0.2 * var(--base-line-height));
  border-radius: 0;
}

.frc__reset-password-message--error {
  color: var(--color-primary--DHL-Red);
  font-size: var(--font-size-1);
  margin: 0;
  line-height: calc(1.2 * var(--base-line-height));
}

.frc__reset-password-message--success {
  color: var(--color-secondary--green-1);
  font-size: var(--font-size-1);
  margin: 0;
}

.frc__reset-password-submit-item {
  padding-top: var(--base-line-height);
}

.frc__reset-password-margin-top {
  margin-top: 10rem;
}
