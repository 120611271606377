/**
 * @file global/_variables.css
 *
 * @description
 * Define all your css-variables.
 * Use the official CSS-Spec, myth.io will generate universal css as long as it's necessary.
 *
 * Import this file in every css file you're using the variables:
 * @import url(_variables.css.css);
 *
 * @see http://dev.w3.org/csswg/css-variables/
 * @see https://github.com/segmentio/myth
 *
 * 1. Break code down into sections, use @section, @subsection
 * 2. Only put one selector per line for a block of rules that apply to multiple selectors.
 * 3. Indent your rules, only one rule per line.
 * 4. Keep proprietary properties directly below the proposed property.
 * 5. Comment your css. Especially hacks or tricky parts using cssdoc-comments
 */

:root {
  /* Colors */

  --color-primary--Postyellow: #ffcc00;
  --color-primary--DHL-Red: #d40511;
  --color-primary--Web-Black: #323232;
  --color-primary--Web-White: #ffffff;

  --color-secondary--grey-1: #666666;
  --color-secondary--grey-2: #b5b5b5;
  --color-secondary--grey-3: #d1d1d1;
  --color-secondary--grey-4: #e8e8e8;
  --color-secondary--grey-5: #f2f2f2;
  --color-secondary--grey-6: #bbbbbb;
  --color-secondary--green-1: #76bd22;
  --color-secondary--green-2: #19a830;
  --color-secondary--red-1: #9d0415;
  --color-secondary--yellow-1: #daae00;
  --color-secondary--yellow-2: #ffe57f;
  --color-secondary--yellow-3: #fff0b3;
  --color-secondary--yellow-4: #f0c202;

  /* Grid */

  --grid-units: 32;
  --grid-gutter: 1%;

  /* Page */

  --width-page-max: 1365px;
  --width-page-min: 320px;

  /**
	 * Typographic baseline
	 *
	 * font-size: 10px;
	 * line-height: 1.75;
	 * scale: 1.25; - major third
	 * @see http://www.gridlover.net/app/

		1.4rem;
		1.6rem;
		2rem;
		2.1rem;
		2.5rem;
		2.7rem;
		3.1rem;
		3.3rem;
		3.9rem;
	 */

  --base-font-size: 10px;
  --base-line-height: 1.4rem;

  /**
	 * Chrome can't handle rem on body
	 * @see https://code.google.com/p/chromium/issues/detail?id=320754
	 * @bug
	 * @css-for: chrome
	 */

  --body-font-size: 1.4em;

  --font-size-1: 1.4rem;
  --font-size-2: 1.6rem;
  --font-size-3: 2rem;
  --font-size-4: 2.1rem;
  --font-size-5: 2.5rem;
  --font-size-6: 2.7rem;
  --font-size-7: 3.1rem;
  --font-size-8: 3.3rem;
  --font-size-9: 3.9rem;

  /* Global component padding */

  --component-wide-padding-s: calc(1.5 * var(--base-line-height));
  --component-wide-padding-m: calc(1.5 * var(--base-line-height));

  /* Multi Select IE Fix **/
  --rmsc-primary: var(--color-primary--Web-Black);
  --rmsc-hover: var(--color-primary--DHL-Red);
  --rmsc-selected: var(--color-secondary--grey-4);
  --rmsc-border: var(--color-secondary--grey-2);
  --rmsc-gray: var(--color-primary--Web-Black);
  --rmsc-background: var(--color-primary--Web-White);
  --rmsc-spacing: 10px;
  --rmsc-border-radius: 4px;
  --rmsc-height: 38px;
}
