.frc__addresspickup-card {
  border: 1px solid var(--color-secondary--grey-6);
  margin: 1%;
  padding: 1.5rem;
  background-color: var(--color-primary--Web-White);
  border-radius: 0.5rem;
  transition: all ease-in 0.3s;
}

.frc__addresspickup-card:hover {
  cursor: pointer;
  background-color: #fff4f1;
  box-shadow: 2px 2px 2px 0px rgba(150, 150, 150, 1);
}

.frc__address-book-grid .rdt_TableRow {
  background-color: var(--color-secondary--grey-5);
}

.frc__address-book-grid .rdt_TableCell {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 481px) and (max-width: 1023px) {
  .frc__addresspickup-card-width {
    width: 48%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .frc__addresspickup-card-width {
    width: 98%;
  }
}

@media screen and (min-width: 1024px) {
  .frc__addresspickup-card-width {
    width: 31%;
  }
}

.frc__address-pickup-header {
  margin-bottom: calc(0.72 * var(--base-line-height));
}

.frc__search-button {
  align-self: flex-end;
  margin-bottom: calc(0.21 * var(--base-line-height));
  margin-top: calc(0.3 * var(--base-line-height));
}

.frc__addresspickup-results {
  margin-top: calc(2.1 * var(--base-line-height));
}

.frc__addresspickup-pagebrowser {
  text-align: right;
  margin-top: calc(0.36 * var(--base-line-height));
}

.frc__addresspickup-pagebrowser-navigate {
  font-weight: bold;
  cursor: pointer;
  margin: 0 calc(0.36 * var(--base-line-height));
}

.frc__input--wrapper.frc__generic--field-wrapper .frc__addressbook-searchinput {
  padding: calc(1 * var(--base-line-height)) calc(0.86 * var(--base-line-height));
}

.frc__input--wrapper.frc__generic--field-wrapper .frc__tooltip-container-flyout {
  transform: translateX(-20%);
}

.frc__tooltip-container.frc__tooltip-container--input::before {
  margin: calc(-0.3 * var(--base-line-height)) calc(0.3 * var(--base-line-height));
}

.frc__button--delete--small {
  border: 1px solid var(--color-secondary--grey-2);
  color: var(--color-secondary--grey-2);
}

.frc__button--delete--small:hover {
  border: 1px solid var(--color-secondary--grey-2);
  color: var(--color-secondary--grey-2);
}

@media screen and (max-width: 767px), print {
  .frc__search-btn-center {
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .frc__address-search--button {
    padding-top: calc(2.6 * var(--base-line-height));
  }

  .frc__address-search--button {
    padding-top: calc(2.5 * var(--base-line-height));
  }
}

.frc__address-book-grid {
  width: 100%;
  border-radius: 5px;
}

.frc__neweset-templates-width {
  width: 95%;
  margin: 0 0 var(--base-line-height);
}

.frc__neweset-templates-width:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 410px) {
  .frc__address-book-col {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .frc__addresspickup-card {
    padding: calc(0.8 * var(--base-line-height));
  }
}
