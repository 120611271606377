.frc__review-summary {
  background-color: var(--color-primary--Web-White);
  border: 1px solid var(--color-secondary--grey-2);
  display: flex;
  flex-wrap: wrap;
  padding: var(--base-line-height);
  justify-content: space-between;
  margin-bottom: calc(1.5 * var(--base-line-height));
  margin-top: calc(0.7 * var(--base-line-height));
}
span.frc__icon-spacing {
  margin-left: calc(0.6 * var(--base-line-height));
}

.frc__review-summary__title {
  width: 100%;
  margin-bottom: calc(0.3 * var(--base-line-height));
}
.frc__summary-header-feedback-text-msg {
  width: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  max-height: 0;
  margin-bottom: 0;
  line-height: 2rem;
}

.frc__summary-header-feedback-text-msg.frc__summary-header-feedback-text-msg--success {
  color: var(--color-secondary--green-1);
  max-height: auto;
  min-height: 40px;
}

.frc__summary-header-feedback-text-msg.frc__summary-header-feedback-text-msg--error {
  color: var(--color-primary--DHL-Red);
  max-height: auto;
  min-height: 40px;
}

.frc__icon {
  color: var(--color-secondary--grey-2);
}

.frc__review-summary__box {
  width: 33%;
  background: var(--color-secondary--grey-5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: calc(1.5 * var(--base-line-height));
  flex-direction: column;
}
.frc__review-summary__box__button {
  background: none;
}
.frc__review-summary__box.fcp__review-summary__quotebox {
  width: auto;
}

.frc__summary-line {
  border-bottom: 1px solid var(--color-secondary--grey-3);
  padding-top: calc(0.8 * var(--base-line-height));
}

.frc__summary-row-section-title {
  padding-top: calc(1.5 * var(--base-line-height));
}
.frc__summary-total-price {
  padding: 1.4rem 0 0 0;
  color: var(--color-secondary--green-1);
}
.frc__price-breakdown-title {
  margin: 0;
}

.frc__detailed-information {
  color: var(--color-secondary--grey-1);
  text-align: left;
  font-style: italic;
  font-size: var(--base-line-height);
}

@media screen and (max-width: 665px) and (min-width: 480px),
  screen and (max-width: 767px) and (min-width: 666px),
  screen and (min-width: 768px),
  print {
  .frc__summary-row-section {
    padding: calc(0.9 * var(--base-line-height)) 0;
  }
}

.frc__summary-header-block {
  padding: 0 calc(0.3 * var(--base-line-height));
  margin-top: calc(0.8 * var(--base-line-height));
}
@media screen and (max-width: 767px) and (min-width: 666px), screen and (min-width: 768px), print {
  .frc__summary-header-block {
    margin-top: calc(0.8 * var(--base-line-height));
  }
}

.frc__summary-header-block-box {
  align-content: flex-start;
  background-color: var(--color-secondary--grey-5);
  padding: calc(0.7 * var(--base-line-height)) calc(0.7 * var(--base-line-height));
}

@media screen and (max-width: 767px) and (min-width: 666px), screen and (min-width: 768px), print {
  .frc__summary-header-buttons {
    margin-top: calc(0.8 * var(--base-line-height));
    margin-left: calc(0.3 * var(--base-line-height));
  }

  .frc__summary-total-price {
    font-size: var(--font-size-5);
  }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .frc__base-button {
    margin: 0.56rem;
  }
}

@media screen and (min-width: 768px) {
  .frc__btn-display-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: calc(0.8 * var(--base-line-height));
  }

  .frc__proceed-to-order-error {
    width: 34%;
  }
}

@media screen and (min-width: 1024px) {
  .frc__proceed-to-order-error {
    width: 30%;
  }
}

.frc__proceed-to-order-error {
  margin-left: auto;
}
