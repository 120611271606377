.frc__address-book-grid {
  width: 100%;
}

@media screen and (max-width: 480px) {
  th:first-child {
    min-width: calc(15 * var(--base-line-height));
  }
}

.item-renderer input[type='checkbox'] {
  -webkit-appearance: checkbox;
}
