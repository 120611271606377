.frc__address-search--wrapper {
  padding: calc(0.8 * var(--base-line-height));
  background-color: var(--color-secondary--grey-4);
  position: relative;
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .frc__base-button {
    margin: 0.56rem;
  }
}

.frc__export-icon {
  margin-right: calc(0.3 * var(--base-line-height));
  height: calc(1.2 * var(--base-line-height));
  width: calc(1.2 * var(--base-line-height));
}

.frc__shipment-search--button {
  padding-bottom: calc(0.2 * var(--base-line-height));
}

@media screen and (min-width: 1024px) {
  .frc__shipment-search--button {
    padding-top: calc(2.6 * var(--base-line-height));
  }
}

.frc__tooltip-container.frc__tooltip-container--input::before {
  margin: calc(-0.25 * var(--base-line-height)) calc(0.14 * var(--base-line-height));
}

@media screen and (min-width: 666px) {
  .frc__shipment--history {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
  }
}
