.frc-message-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.35);
}

.frc-message-modal__message {
  margin: 1rem 2rem;
}
