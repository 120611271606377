.frc__phone-number {
}

@media screen and (min-width: 480px), print {
  .frc-select-phone-number--wrapper {
    padding-right: calc(0.8 * var(--base-line-height));
  }
}

.frc-select-phone-number--wrapper label {
  display: block;
}
.frc-select-phone-number--wrapper select {
  width: 100%;
}

.fcp__phone-number-delete-button--wrapper {
  position: relative;

  /* FIXME: REMOVE COMMENTED LINES */
  /* right: calc(-0.2 * var(--base-line-height));
  bottom: calc(4.85 * var(--base-line-height)); */

  right: calc(-0.2 * var(--base-line-height));
  bottom: calc(5.5 * var(--base-line-height));
}
