.frc__reference-cell {
  color: var(--color-secondary--grey-2);
  font-style: italic;
}

.frc__custom-cell-line-break {
  display: block;
}

.frc__status_icon {
  color: var(--color-primary--Postyellow);
}

.frc__operation-custom-cell-expander {
  margin-left: calc(8 * var(--base-line-height));
}

.frc__icon-pointer {
  cursor: pointer;
}

.frc__icon {
  margin-right: calc(0.3 * var(--base-line-height));
}

.frc__operation-icon {
  color: var(--color-primary--DHL-Red);
}

.frc__summary--row {
  display: flex;
  justify-content: space-between;
}

.frc__summary--row div:last-child {
  font-weight: bold;
  padding-left: calc(1.5 * var(--base-line-height));
}
.frc__summary--row.l-grid--left-s div:last-child {
  padding-left: calc(0.5 * var(--base-line-height));
}
.frc__summary--row.l-grid--left-s {
  justify-content: flex-start;
}

.reference {
  margin-top: calc(0.5 * var(--base-line-height));
}

@media (max-width: 1023px) {
  .frc__summary--row div:last-child {
    font-weight: bold;
  }
}
