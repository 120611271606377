.frc__template-search--button {
  padding-bottom: calc(0.2 * var(--base-line-height));
}

@media screen and (min-width: 1023px) {
  .frc__template-search--button {
    padding-top: calc(2.6 * var(--base-line-height));
  }
  .frc__tooltip-container-flyout {
    margin-left: calc(2 * var(--base-line-height));
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .frc__base-button {
    margin: 0.56rem;
  }
}
