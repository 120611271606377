.frc__modal-view-edit-list {
  overflow: auto;
  width: 100%;
}

.frc__modal-max-width {
  max-height: calc(19 * var(--base-line-height));
}

.frc__modal-content {
  font-size: 14px;
  line-height: 16px;
  background-color: var(--color-primary--Web-White);
}

.frc__modal-padding {
  padding: calc(0.72 * var(--base-line-height));
}

.frc__modal-attribute-color {
  color: var(--color-secondary--grey-2);
}

.frc__modal--row div:last-child {
  padding-left: calc(0.5 * var(--base-line-height));
}

.frc__modal-content:nth-child(even) {
  background: #e8e8e8;
}

.frc__modal-view-edit-center {
  text-align: center;
  line-height: 1px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .frc__modal-view-edit-center {
    text-align: center;
    line-height: 15px;
  }
}

.frc__modal-dialog-width {
  max-width: 26em;
}

.frc__modal-content:nth-child(even) {
  background-color: #f8f8f8;
}

.frc__delete-contact-msg {
  color: var(--color-primary--DHL-Red);
}

.frc__btn-margin-right {
  margin-right: calc(0.75 * var(--base-line-height));
}

.frc__generic--loader-overlay.is-loading {
  display: block;
  height: 100%;
  z-index: 999;
}

.frc__generic--loader-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: none;
}

.frc__modal-center {
  text-align: center;
}

.frc__summary-modal-row {
  display: flex;
}

.frc__summary-modal-row div:first-child {
  padding-right: calc(0.54 * var(--base-line-height));
}

.frc__summary-modal-row div:last-child {
  font-weight: bold;
}

.frc__modal-dialog-width-small {
  max-width: 28em !important;
}
