.frc__card-list-container {
  border: 1px solid var(--color-secondary--grey-5);
  max-height: calc(28 * var(--base-line-height));
  overflow-y: scroll;
}

.frc__card-list-card-wrapper {
  cursor: pointer;
  background-color: var(--color-secondary--grey-5);
  padding: calc(0.7 * var(--base-line-height));
}

.frc__card-list-card-wrapper:nth-child(even) {
  background-color: var(--color-primary--Web-White);
}
.frc__card-list-title {
  font-weight: bold;
  font-size: var(--font-size-2);
}

.frc__card-list-subtitle {
  font-size: var(--font-size-2);
  font-weight: bold;
}

.frc__card-list-street {
  font-size: var(--font-size-1);
}

.frc__card-list-city {
}

.here-maps-container {
  height: calc(28 * var(--base-line-height));
  position: relative;
  width: 100%;
}

.frc__marker-card-wrapper {
  display: flex;
  justify-content: center;
}

.frc__marker-card-container {
  max-width: 100%;
  width: calc(20 * var(--base-line-height));
  background-color: var(--color-primary--Web-White);
  position: absolute;
  top: var(--base-line-height);
  padding: calc(0.8 * var(--base-line-height));
  border-radius: calc(0.3 * var(--base-line-height));
  text-align: center;
}
