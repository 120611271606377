.c-product-width {
  margin: 0.2%;
}

.c-freightcalc--pricelist-checkbox.c-freightcalc--pricelist-checkbox--disabled {
  background-color: var(--color-secondary--grey-2);
}

.fcp__is-selected {
  background-color: var(--color-secondary--grey-3);
  border: 1px solid var(--color-primary--DHL-Red);
  min-height: 100%;
}
