.frc__additional-services--wrapper {
  margin-bottom: var(--base-line-height);
  position: relative;
}

.frc_additional-services--checkbxes-sub-group-title {
  margin-left: calc(3 * var(--base-line-height));
  margin-bottom: var(--base-line-height);
  position: relative;
  cursor: pointer;
  font-weight: 700;
  top: calc(0.5 * var(--base-line-height));
}

.frc_additional-services--checkbxes-sub-group-title::before {
  content: '\e604';
  font-family: 'dhlicons';
  position: absolute;
  top: 0;
  left: calc(-3 * var(--base-line-height));
  font-size: var(--font-size-3);
  top: 20%;
}
.frc_additional-services--checkbxes-sub-group.expand .frc_additional-services--checkbxes-sub-group-title::before {
  content: '\e60e';
  font-size: var(--font-size-2);
  top: 1%;
}

.frc_additional-services--checkbxes-sub-group.expand {
  border: 1px solid var(--color-secondary--grey-3);
  animation: subGroupFadeIn 1.2s;
  display: block !important;
}
.frc__special_case {
  display: none;
}
