.frc__generic-section--wrapper {
  padding: calc(1.5 * var(--base-line-height));
  /* margin-bottom: 1rem; */
  background-color: var(--color-secondary--grey-5);
}

.frc__p {
  margin-bottom: 0;
}

.frc__generic--field-wrapper,
.frc__generic--field-wrapper--no-top {
  padding-top: var(--base-line-height);
  padding-left: calc(0.3 * var(--base-line-height));
  padding-right: calc(0.3 * var(--base-line-height));
}

.frc__generic--field-wrapper--no-top {
  padding-top: 0;
}

.frc__generic-button {
  line-height: var(--base-line-height);
}

.frc__generic-button.disabled {
  background-color: var(--color-secondary--grey-3);
  border: 2px solid var(--color-primary--Web-White);
  cursor: not-allowed;
}

.frc__generic-button.disabled:hover span {
  color: var(--color-primary--Web-White);
}

.frc__generic-button.base-button--white.disabled {
  border: 2px solid var(--color-primary--DHL-Red);
}

.frc__generic-button.base-button--white.disabled:hover span {
  color: var(--color-primary--DHL-Red);
}

.frc__generic-button.base-button span {
  white-space: normal;
}

.frc__button-icon--align::before {
  padding-right: calc(0.5 * var(--base-line-height));
}

.base-button.frc__base-button--input {
  min-height: calc(2.51 * var(--base-line-height));
  top: calc(0.1 * var(--base-line-height));
  margin-bottom: 0;
}

.frc__generic--list {
  padding: 0 calc(2 * var(--base-line-height));
}

.frc__generic--list.list {
  margin-bottom: 0;
}

.frc__generic--alert,
.frc__generic--done {
  background-color: var(--color-primary--Postyellow);
  color: var(--color-primary--Web-Black);
  padding: calc(0.5 * var(--base-line-height));
  margin-bottom: calc(0.2 * var(--base-line-height));
}

.frc__generic--done {
  background-color: var(--color-secondary--grey-4);
  color: var(--color-secondary--grey-2);
}

.frc__generic--margin-top-0 {
  margin-top: calc(0.6 * var(--base-line-height));
}
.frc-label {
  display: block;
}

.frc-has-loader-icon::before {
  background-color: rgba(255, 255, 255, 0.7);
  background-image: url('../../css/images/loader_tt.svg');
  background-repeat: no-repeat;
  background-position: center;
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
}

/* Below it's the codebase which has been moved from the old Dashboard.
This codebase is not a real BEM, which is an exception in our project.
*/

.c--fcp-dashboard {
  background-color: var(--color-secondary--grey-5);
  padding: calc(2 * var(--base-line-height)) calc(1 * var(--base-line-height));
}

.c--fcp-dashboard-header {
  border-bottom: 1px solid var(--color-secondary--grey-3);
  margin-bottom: calc(1.5 * var(--base-line-height));
}

.c--fcp-dashboard-header-title {
  margin-bottom: calc(0.5 * var(--base-line-height));
}

.c--fcp-dashboard-component {
  background-color: var(--color-secondary--grey-4);
  padding: calc(0.9 * var(--base-line-height));
  box-sizing: border-box;
  border: calc(0.5 * var(--base-line-height)) solid var(--color-secondary--grey-5);
  position: relative;
}

.c--fcp-dashboard-info-user-name {
  padding: 0;
  margin: 0;
}

.c--fcp-dashboard-info-user-company {
  padding: 0;
  padding-bottom: calc(0.5 * var(--base-line-height));
  margin: 0;
  font-weight: 900;
  font-size: var(--font-size-5);
}

.c--fcp-dashboard-info-user-account {
  font-size: var(--font-size-3);
  color: var(--color-secondary--grey-1);
}

.c--fcp-dashboard-account-select {
  color: var(--color-secondary--grey-1);
  background: none;
  border: 1px solid var(--color-primary--Web-White);
  padding: 0 calc(0.5 * var(--base-line-height));
  margin-left: calc(0.5 * var(--base-line-height));
  height: calc(2 * var(--base-line-height));
  font-size: var(--font-size-3);
  font-weight: 900;
  cursor: pointer;
}

.c--fcp-dashboard-account-select option {
  color: var(--color-primary--Web-Black);
  font-weight: bold;
}

.c--fcp-dashboard-component-wrapper {
  height: 100%;
  width: 100%;
}

.c--fcp-dashboard-component-yellow {
  background-color: var(--color-primary--Postyellow);
}

.c--fcp-dashboard-component-title {
  font-weight: 700;
  font-size: var(--font-size-4);
  margin-bottom: calc(0.5 * var(--base-line-height));
}

.c--fcp-dashboard-component-title--underlined {
  border-bottom: 1px solid var(--color-secondary--grey-3);
  margin-bottom: calc(1.5 * var(--base-line-height));
}

.c--fcp-dashboard-component-title .c-icon-bar--icon {
  padding-right: calc(0.9 * var(--base-line-height));
}

.c--fcp-component-title-icon {
  height: calc(2.8 * var(--base-line-height));
  width: calc(2.8 * var(--base-line-height));
}

.c--fcp-dashboard-last-login {
  display: none;
}

@media (min-width: 1024px) {
  .c--fcp-aligned-input {
    padding-right: var(--base-line-height);
  }
}

.c-form--element-input--all-borders.c-form--element-input--all-borders--large {
  padding: calc(0.75 * var(--base-line-height)) var(--base-line-height);
}

.c--fcp-l-grid-column {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  flex-wrap: wrap;
}

.c--fcp-dashboard-component-remove {
  position: absolute;
  cursor: pointer;
  display: block;
  right: calc(0.9 * var(--base-line-height));
  top: calc(0.3 * var(--base-line-height));
  z-index: 10;
}

.c--fcp-dashboard-component-remove span {
  color: var(--color-secondary--grey-3);
  padding: 0;
}

.c--fcp-dashboard-component-remove span:hover {
  color: var(--color-primary--DHL-Red);
}

.c--fcp-icon-close-bold::before {
  font-size: var(--base-font-size);
  content: '\e610';
}

.c--fcp-dashboard-component-dragover {
  background-color: var(--color-secondary--yellow-3);
}

.c--fcp-dashboard-component div {
  pointer-events: none;
}
.c--fcp-dashboard-component.enable-pointer-events div {
  pointer-events: initial;
}

.c--fcp-dashboard-component .base-button,
.c--fcp-dashboard-component .c--fcp-dashboard-component-remove,
.c--fcp-dashboard-component .c-form--element-input--all-borders,
.c--fcp-dashboard-component .c-form--element-input,
.c--fcp-dashboard-component .c--fcp-dashboard-account-select {
  pointer-events: auto;
  line-height: 1rem;
}

@media (max-width: 767px) {
  .c--fcp-dashboard {
    padding: calc(2 * var(--base-line-height)) 0 0 0;
  }

  .c--fcp-dashboard-component {
    border-width: calc(0.5 * var(--base-line-height));
  }
}

.c--fcp-dashboard-component-dragover::before {
  content: '';
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: calc(-0.7 * var(--base-line-height));
  width: calc(0.4 * var(--base-line-height));
  background-color: var(--color-secondary--yellow-1);
}

.c--fcp-dashboard-settings-icon {
  position: relative;
  top: calc(0.3 * var(--base-line-height));
  width: calc(1.2 * var(--base-line-height));
  height: calc(1.2 * var(--base-line-height));
  margin-left: calc(0.5 * var(--base-line-height));
}

.c--fcp-dashboard--input {
  color: #666666;
  font-size: 1.4rem;
  background: #ffffff;
  line-height: 1.9rem;
  margin: 0;
  width: 100%;
  border: 1px solid #b5b5b5;
  margin-bottom: 0.28rem;
}

.frc__generic--background-white {
  background-color: var(--color-primary--Web-White);
}

.frc__view-shipment-cursor:hover {
  cursor: pointer;
}

.c--fcp-dashboard-component-content span {
  padding-top: 1%;
}

/**IE */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .c--fcp-dashboard-component-content span {
    padding-top: 5%;
  }
}

.frc__chat-with-us {
  font-size: calc(1.1 * var(--base-line-height));
  color: var(--color-primary--DHL-Red);
}

.frc__chat-with-us:hover {
  color: var(--color-primary--Web-Black);
}
