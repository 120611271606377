.frc__shipment-summary {
  background-color: var(--color-secondary--grey-4);
  padding: calc(1.5 * var(--base-line-height));
}

.frc__shipment-summary--row {
  display: flex;
  justify-content: space-between;
}

.frc__shipment-summary--row.l-grid--left-s {
  justify-content: flex-start;
}

.frc__shipment-summary--row div:last-child {
  font-weight: bold;
  padding-left: calc(1.5 * var(--base-line-height));
}

.frc__shipment-summary--row.l-grid--left-s div:last-child {
  padding-left: calc(0.5 * var(--base-line-height));
}

.frc__total-errors--wrapper .frc__error {
  font-weight: bold;
  font-size: var(--font-size-2);
}

@media screen and (min-width: 768px), print {
  .frc__total-errors--wrapper {
    padding-top: calc(1.5 * var(--base-line-height));
  }
}
