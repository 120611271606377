.frc__shipment-details-sec {
  border: 1px solid var(--color-secondary--grey-4);
}

.frc__accordion-caret-end {
  margin-left: auto;
  margin-top: calc(-0.8 * var(--base-line-height));
}

.frc__data-grid-email {
  color: var(--color-primary--DHL-Red);
  word-break: break-all;
}

@media (min-width: 768px) {
  .frc__shipment-details-service-point {
    padding-left: 50%;
  }
}

.frc__delete-btn-small {
  background-color: var(--color-primary--DHL-Red);
  font-size: calc(1 * var(--base-line-height));
}

.frc__shipment-detail-close-btn {
  position: relative;
}

.frc__shipment-detail-vas-entry {
  text-align: left;
  padding: calc(1 * var(--base-line-height));
}

.frc__shipment-detail-vas-entry:last-child {
  width: auto;
}

.frc__shipment-Detail-vas-width {
  border: solid 1px var(--color-secondary--grey-2);
}

.frc__generic--loader-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: none;
}

.frc__generic--loader-overlay.is-loading {
  display: block;
  height: 100%;
  z-index: 999;
  background-attachment: fixed;
}

.instructions {
  background: white !important;
  color: #000000 !important;
}
