.frc__markup-attribute {
  margin-top: calc(3.3 * var(--base-line-height));
}
.frc__markup-separator {
  padding-left: calc(0.55 * var(--base-line-height));
}

.c-freightportal--surcharge-input .frc__markup-attribute {
  margin-bottom: calc(1.46 * var(--base-line-height));
}
