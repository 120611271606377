.frc__input {
  padding: calc(0.5 * var(--base-line-height)) var(--base-line-height);
  color: var(--color-primary--Web-Black);
  font-size: var(--font-size-1);
  background: var(--color-primary--Web-White);
  line-height: calc(1.5 * var(--base-line-height));
  margin: 0;
  width: 100%;
  border: 1px solid var(--color-secondary--grey-2);
  margin-bottom: calc(0.2 * var(--base-line-height));
  border-radius: 0;
}

.frc__input.disabled {
  background: var(--color-secondary--grey-5);
  opacity: 0.8;
}

.frc__input--wrapper {
  display: block;
}

.frc__input--wrapper-flex {
  display: flex;
  flex-direction: column;
  /* height: 100%;
  justify-content: space-between; */
}

.frc-select--wrapper label,
.frc__input--wrapper-flex label {
  display: flex;
  flex-direction: column;
}

.frc__input-label--grayed-out {
  color: var(--color-secondary--grey-2);
  font-style: italic;
}

.frc__input--grayed-out {
  color: var(--color-secondary--grey-2);
  font-style: italic;
  border: 1px solid var(--color-secondary--grey-2);
}

@media screen and (max-width: 767px), print {
  .frc-select--wrapper label,
  .frc__input--wrapper-flex label {
    flex-direction: row;
  }

  .frc-select--wrapper label small,
  .frc__input--wrapper-flex label small {
    padding-left: calc(0.25 * var(--base-line-height));
  }
}

.frc__tooltip-container.frc__tooltip-container--input::before {
  margin: calc(-0.4 * var(--base-line-height)) calc(0.7 * var(--base-line-height));
}

.frc__onFocusTooltip:focus + .frc__onFocusTooltip-container .frc__onFocusTooltip-flyout {
  visibility: visible;
  opacity: 1;
}

.frc__onFocusTooltip-container {
  position: relative;
  overflow: visible;
}

.frc__onFocusTooltip-flyout {
  background-color: #666666;
  border-radius: calc(0.14 * var(--base-line-height));
  bottom: calc(5 * var(--base-line-height));
  box-shadow: 0 1px calc(0.2 * var(--base-line-height)) rgba(0, 0, 0, 0.2);
  color: #ffffff;
  line-height: 1;
  min-width: calc(17.14 * var(--base-line-height));
  opacity: 0;
  padding: calc(0.71 * var(--base-line-height));
  position: absolute;
  transition: opacity 0.25s;
  -webkit-transition: opacity 0.25s;
  white-space: normal;
  visibility: hidden;
  z-index: 100;
}
