.frc__checkbox {
  /* 
  width: calc(1.5 * var(--base-line-height));
  height: calc(1.5 * var(--base-line-height));
  padding: calc(0.5 * var(--base-line-height)) var(--base-line-height);
  border: 1px solid var(--color-secondary--grey-2);
  color: var(--color-primary--Web-Black);
  font-size: var(--font-size-1);
  background: var(--color-primary--Web-White);
  line-height: calc(1.5 * var(--base-line-height));
  margin: 0;
  margin-bottom: calc(0.2 * var(--base-line-height));
  border-radius: 0; */
}

.c-fcp-freightcalc-additional-service-checkbox-price {
  margin: calc(0.45 * var(--base-line-height)) 0 0 calc(0.45 * var(--base-line-height));
  color: var(--color-secondary--green-2);
}

.frc__checkbox--vertical {
  height: 100%;
}

.frc__checkbox--vertical .c-form-step--item.c-freightcalc--item-checkbox {
  /* height: 100%; */
}

.frc__checkbox--vertical label {
  /* height: 100%; */
}

.frc__checkbox--vertical .c-form--element-checkbox ~ .c-form--element-label {
  padding-left: calc(1.05 * var(--base-line-height));
  min-height: calc(7 * var(--base-line-height));
  overflow: visible;
  /* height: 100%; */
}

.frc__checkbox--vertical .c-form--element-checkbox ~ .c-form--element-label.c-fcp--checkbox-label {
  padding-top: 0;
}

.frc__checkbox--vertical .c-form--element-label-checkbox::before,
.frc__checkbox--vertical .c-form--element-label-checkbox::after {
  left: calc(2.5 * var(--base-line-height));
  top: calc(3.8 * var(--base-line-height));
}

@media screen and (max-width: 767px), print {
  .frc__checkbox--vertical .c-form--element-checkbox ~ .c-form--element-label.c-fcp--checkbox-label {
    margin: calc(0.5 * var(--base-line-height)) 0;
    padding: calc(1.1 * var(--base-line-height)) 0 0 calc(2.6 * var(--base-line-height));
    line-height: calc(2.1 * var(--base-line-height));
  }

  .frc__checkbox--vertical .c-form--element-label-checkbox::before,
  .frc__checkbox--vertical .c-form--element-label-checkbox::after {
    top: calc(1.1 * var(--base-line-height));
    left: calc(0.25 * var(--base-line-height));
  }

  .frc__checkbox--vertical .c-form-step--item.c-freightcalc--item-checkbox {
    max-height: calc(4 * var(--base-line-height));
  }
}

/* .frc__label--checkbox {
  position: relative;
  top: calc(-0.4 * var(--base-line-height));
  margin-left: calc(0.5 * var(--base-line-height));
} */

.c-form-step--item-checkbox.c-form-step--item-checkbox {
  background: none;
  border: none;
  float: left;
  left: calc(-1 * var(--base-line-height));
  width: calc(3 * var(--base-line-height));
  margin-bottom: calc(-1 * var(--base-line-height));
}

.frc__label--checkbox {
  position: relative;
  left: calc(-1 * var(--base-line-height));
  top: var(--base-line-height);
  /* margin-bottom: calc(1.8 * var(--base-line-height)); */
  line-height: calc(1.6 * var(--base-line-height));
}

.l-view input.frc__checkbox {
  -webkit-appearance: checkbox;
}

.frc__checkbox.disabled {
  background: var(--color-secondary--grey-5);
  opacity: 0.8;
}

.frc__checkbox--wrapper {
  display: block;
}

.frc__checkbox--wrapper.disabled {
  opacity: 0.5;
  font-style: italic;
}

.frc__checkbox--wrapper::after {
  content: '';
  clear: both;
  display: block;
}
