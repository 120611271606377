.frc__edit-space-divider {
  width: calc(5.5 * var(--base-line-height));
  height: auto;
  display: inline-block;
}
@media screen and (min-width: 768px), print {
  .frc__wrap-contact-ref-detail {
    width: 90%;
  }
  .frc__edit-action-btn-left {
    margin-left: auto;
  }
}

.frc__edit-contact-field-wrapper {
  padding-top: var(--base-line-height);
  padding-left: calc(0.3 * var(--base-line-height));
  padding-right: calc(0.3 * var(--base-line-height));
}

.frc__delete-dialog-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  margin: calc(2 * var(--base-line-height)) 0;
  max-height: 92vh;
  overflow: hidden;
}

.frc__dialog-width {
  width: 100%;
}

@media screen and (min-width: 1600px), print {
  .frc__dialog-width {
    left: 20%;
    width: 65%;
  }
}

.frc__btn-margin-right {
  margin-right: calc(0.75 * var(--base-line-height));
}

.frc__app-template__override {
  padding-left: calc(var(--base-line-height) * 0.5);
  padding-right: calc(var(--base-line-height) * 0.5);
}

.frc__app-template__override h3 {
  color: #191919 !important;
  cursor: default !important;
  margin: 0 !important;
}

.frc__app-template__override .frc__app-tab {
  background-color: var(--color-secondary--grey-4) !important;
  cursor: default !important;
  flex-grow: 0 !important;
}
