.frc__address-pickup-header {
  margin-bottom: calc(0.72 * var(--base-line-height));
}

.frc__search-button {
  align-self: flex-end;
  margin-bottom: calc(0.21 * var(--base-line-height));
  margin-top: calc(0.3 * var(--base-line-height));
}

.frc__addresspickup-results {
  margin-top: calc(2.1 * var(--base-line-height));
}

.frc__address-no-results {
  padding-left: calc(0.3 * var(--base-line-height));
}

.frc__addresspickup-pagebrowser {
  text-align: right;
  margin-top: calc(0.36 * var(--base-line-height));
}

.frc__addresspickup-pagebrowser-navigate {
  font-weight: bold;
  cursor: pointer;
  margin: 0 calc(0.36 * var(--base-line-height));
}

.frc__input--wrapper.frc__generic--field-wrapper .frc__addressbook-searchinput {
  padding: calc(1 * var(--base-line-height)) calc(0.86 * var(--base-line-height));
}

.frc__input--wrapper.frc__generic--field-wrapper .frc__tooltip-container-flyout {
  transform: translateX(-20%);
}

.frc__tooltip-container.frc__tooltip-container--input::before {
  margin: calc(-0.3 * var(--base-line-height)) calc(0.3 * var(--base-line-height));
}

.frc__button--delete--small {
  border: 1px solid var(--color-secondary--grey-2);
  color: var(--color-secondary--grey-2);
}

.frc__button--delete--small:hover {
  border: 1px solid var(--color-secondary--grey-2);
  color: var(--color-secondary--grey-2);
}

@media screen and (max-width: 767px), print {
  .frc__search-btn-center {
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .frc__address-search--button {
    padding-top: calc(2.6 * var(--base-line-height));
  }

  .frc__address-search--button {
    padding-top: calc(2.5 * var(--base-line-height));
  }
}
