.frc__contact-details-label {
  padding-top: calc(2 * var(--base-line-height));
  margin-bottom: 0;
}

.frc__address-details-label {
  padding-top: calc(0.93 * var(--base-line-height));
  margin-bottom: 0;
}

.frc__address-book-btn {
  display: flex;
  justify-content: flex-end;
}

.frc__company-search-position {
  position: relative;
}

.frc__almost-there {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-primary--DHL-Red);
}

.frc__email-checkbox-confirmation {
  position: absolute;
  top: 10px;
  right: 10px;
}
