.frc__multiselect_bottom {
  margin-bottom: calc(1 * var(--base-line-height));
  --rmsc-primary: var(--color-primary--Web-Black);
  --rmsc-hover: var(--color-primary--DHL-Red);
  --rmsc-selected: #fff4f1;
  --rmsc-border: var(--color-secondary--grey-2);
  --rmsc-gray: var(--color-primary--Web-Black);
  --rmsc-spacing: 10px;
  --rmsc-border-radius: 4px;
  --rmsc-height: 38px;
  transition: none;
}

.frc__margin-top {
  margin-top: calc(1.4 * var(--base-line-height));
}

.select-panel {
  overflow: hidden;
}

.select-item.false:hover {
  color: var(--color-primary--Web-White);
  transition: none;
}

.frc__margin-sides-1 {
  margin: 0 var(--base-line-height);
}

span.dropdown-heading-dropdown-arrow.gray {
  color: var(--color-primary--DHL-Red);
}

.item-renderer {
  transition: none;
}

.select-item {
  transition: none;
}

@media (max-width: 480px) {
  .frc_country-select-margin {
    margin: auto;
  }
}
