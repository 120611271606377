tr:nth-child(6n-5),
tr:nth-child(6n-4),
tr:nth-child(6n-3) {
    background: var(--color-primary--Web-White);
}

tr:nth-child(6n-2),
tr:nth-child(6n-1),
tr:nth-child(6n) {
    background-color: #f8f8f8;
}
