.frc__side-summary-panel--wrapper {
  width: 100%;
  height: 100%;
  margin-right: calc(1.3 * var(--base-line-height));
  /* background-color: var(--color-primary--Web-White); */
  position: relative;
}

.frc__side-summary-panel {
  padding: calc(2 * var(--base-line-height)) var(--base-line-height);
  background-color: var(--color-secondary--grey-3);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.frc__shipment-type {
  margin-bottom: 0;
  text-transform: capitalize;
}

.frc__pickup-location {
  margin-bottom: 0;
  word-break: break-all;
}

.frc__shipment-details-p {
  margin-bottom: 0;
}

.frc__pickup-options {
  display: flex;
  justify-content: space-between;
}

.frc__bold {
  font-weight: bold;
}

.frc__word-wrap {
  word-wrap: break-word;
}
.c--fcp-summary-subtitle {
  padding: 0;
  margin: 0;
  position: relative;
  margin-bottom: 0.7rem;
  letter-spacing: -1px;
}
.c--fcp-summary-subtitle span {
  font-size: 1.4rem;
  font-weight: normal;
  position: absolute;
  right: 0;
}

.frc__summary_panel_title {
  display: inline-block;
}

.fcp__summary-panel-title {
  margin-top: calc(0.07 * var(--base-line-height));
  margin-bottom: calc(0.33 * var(--base-line-height));
  padding: calc(0.07 * var(--base-line-height)) 0 calc(0.07 * var(--base-line-height));
}
