.frc__pagination-magin-top {
  margin-top: calc(0.8 * var(--base-line-height));
}

.frc__pagination-btn-wrapper {
  margin-left: auto;
}

.frc__pagination-btn {
  color: var(--color-secondary--grey-1);
  border-radius: 4px;
  padding: calc(0.8 * var(--base-line-height)) calc(1.1 * var(--base-line-height)) calc(0.8 * var(--base-line-height))
    calc(1.1 * var(--base-line-height));
  border: 1px solid var(--color-secondary--grey-1);
  margin: calc(0.5 * var(--base-line-height));
  background-color: var(--color-primary--Web-White);
}

.frc__pagination-last-edge-btn {
  padding: calc(0.8 * var(--base-line-height)) calc(0.95 * var(--base-line-height)) calc(0.8 * var(--base-line-height))
    calc(0.8 * var(--base-line-height));
}

.frc__page-hover:hover {
  color: var(--color-primary--Web-White);
  background-color: var(--color-primary--DHL-Red);
}

.frc__pagination-select-display {
  display: flex;
  margin: 0 auto;
}

.frc__pagination-display {
  width: calc(5 * var(--base-line-height));
  align-items: center;
  display: flex;
}

.frc__pagination-select {
  margin-top: calc(0.5 * var(--base-line-height));
}

.frc__pagination-btn-center {
  margin: 0 auto;
}

@media (min-width: 320px) and (max-width: 767px) {
  .frc__pagination-btn-margin {
    margin: calc(0.5 * var(--base-line-height)) calc(0.2 * var(--base-line-height)) 0
      calc(0.5 * var(--base-line-height));
  }
}

.frc__pagination-btn.disabled {
  opacity: 0.3;
  cursor: not-allowed;
  background-color: var(--color-secondary--grey-3);
}

.frc__simple-pagination-range {
  margin: calc(1.7 * var(--base-line-height)) calc(1 * var(--base-line-height));
}
