.frc-select--wrapper {
  display: block;
}

.frc__select--wrapper-flex {
  display: flex;
  flex-direction: column;
  /* height: 100%;
  justify-content: space-between; */
}

.frc-select--wrapper label,
.frc__select--wrapper-flex label {
  display: flex;
  width: 100%;
}

.frc-select {
  padding: calc(0.56 * var(--base-line-height)) var(--base-line-height);
  color: var(--color-secondary--grey-1);
  font-size: var(--font-size-1);
  background: var(--color-primary--Web-White);
  line-height: calc(1.5 * var(--base-line-height));
  margin: 0;
  border: 1px solid var(--color-secondary--grey-2);
  margin-bottom: calc(0.2 * var(--base-line-height));
  border-radius: 0;
}

.c-form--element-select[disabled]::-ms-value {
  background-color: var(--color-secondary--grey-3);
}

.c-form--element-base.c-form--element-select.no-margin-bottom {
  margin-bottom: 0;
}
