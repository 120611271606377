.frc__input_file {
  padding: 3%;
  width: 100%;
  background-color: var(--color-primary--Web-White);
}
.frc__upload_document {
  display: flex;
  border: 1px solid var(--color-secondary--grey-1);
  background-color: var(--color-primary--Web-White);
  border-radius: 0.4rem;
  font-size: 1.4rem;
  margin-bottom: 1%;
}
.has-error {
  border: 1px solid var(--color-primary--DHL-Red);
}

.frc__remove-button {
  align-self: center;
  margin-right: calc(0.7 * var(--base-line-height));
  border: 1px solid var(--color-secondary--grey-2);
  border-radius: calc(0.2 * var(--base-line-height));
  color: var(--color-secondary--grey-2);
  cursor: pointer;
  line-height: calc(1.5 * var(--base-line-height));
  padding: 0 calc(0.5 * var(--base-line-height));
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background: none;
}
.frc__remove-button:hover {
  border: 1px solid var(--color-primary--DHL-Red);
  color: var(--color-primary--DHL-Red);
}

.frc__dhlicon-add::before {
  content: '\e605';
}
.frc__padding-right {
  padding-right: calc(0.5 * var(--base-line-height));
}
