.frc__shipment-details-row {
  position: relative;
  background: var(--color-primary--Web-White);
  padding: calc(0.5 * var(--base-line-height)) 0 calc(1 * var(--base-line-height)) calc(0.5 * var(--base-line-height));
}

.frc__shipment-details-row .frc__button--delete {
  top: calc(0.5 * var(--base-line-height));
  padding-top: calc(0.1 * var(--base-line-height));
  padding-bottom: 0;
  line-height: calc(1.7 * var(--base-line-height));
}

.frc__shipment-row-margin-top {
  margin-top: calc(0.9 * var(--base-line-height));
}

.frc__shipment-details-row .frc__error {
  margin-bottom: calc(1.1 * var(--base-line-height));
}

.frc__shipment-details-row .frc__input--wrapper-flex label,
.frc__shipment-details-row .frc__select--wrapper-flex label {
  min-height: calc(3 * var(--base-line-height));
}

@media screen and (max-width: 767px), print {
  .frc__shipment-details-row .frc__input--wrapper-flex label,
  .frc__shipment-details-row .frc__select--wrapper-flex label {
    margin-top: calc(1.1 * var(--base-line-height));
    min-height: auto;
  }

  .frc__shipment-details-row .frc__error {
    margin-bottom: calc(0.2 * var(--base-line-height));
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px), print {
  .frc__shipment-details-row .c-form--element-checkbox ~ .c-form--element-label.c-fcp--checkbox-label {
    margin-top: calc(0.7 * var(--base-line-height));
  }

  .frc__shipment-details-row .frc__column-wrapper--short .frc__input--wrapper-flex label,
  .frc__shipment-details-row .frc__column-wrapper--short .frc__shipment-details-row .frc__select--wrapper-flex label {
    min-height: auto;
    margin-top: calc(0.7 * var(--base-line-height));
  }

  .frc__shipment-details-row .frc__column-wrapper--short .c-form--element-label-checkbox::before,
  .frc__shipment-details-row .frc__column-wrapper--short .c-form--element-label-checkbox::after {
    left: calc(2.5 * var(--base-line-height));
    top: calc(3.7 * var(--base-line-height));
  }
}

.frc__dangerous-goods {
  padding: calc(0.7 * var(--base-line-height)) calc(0.7 * var(--base-line-height)) calc(1 * var(--base-line-height))
    calc(1 * var(--base-line-height));
  margin: 0 calc(1.2 * var(--base-line-height)) 0 calc(0.7 * var(--base-line-height));
  border: 2px solid var(--color-secondary--grey-3);
  border-radius: calc(0.5 * var(--base-line-height));
}

.frc__shipment-details-row .c-form--element-checkbox ~ .c-form--element-label.c-fcp--checkbox-label {
  margin-left: calc(0.3 * var(--base-line-height));
}
