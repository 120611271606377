.frc__change-password {
  padding: calc(2 * var(--base-line-height));
}

.frc__form--element-input--all-borders {
  padding: calc(0.5 * var(--base-line-height)) var(--base-line-height);
  color: var(--color-secondary--grey-1);
  font-size: var(--font-size-1);
  background: var(--color-primary--Web-White);
  line-height: calc(1.5 * var(--base-line-height));
  margin: 0;
  width: 100%;
  border: 1px solid var(--color-secondary--grey-2);
  margin-bottom: calc(0.2 * var(--base-line-height));
  border-radius: 0;
}

.frc__change-password-message--error {
  color: var(--color-primary--DHL-Red);
  font-size: var(--font-size-1);
  margin: 0;
  line-height: calc(1.2 * var(--base-line-height));
}

.frc__change-password-message--success {
  color: var(--color-secondary--green-1);
  font-size: var(--font-size-1);
  margin: 0;
}

.frc__change-password-submit-item {
  padding-top: var(--base-line-height);
}

.frc__change-password-submit-item .base-button {
  padding: calc(0.35 * var(--base-line-height)) 0;
}

.frc__change-password .c--fcp-registration-form--input-container--info-flyout {
  left: 0;
  transform: translateX(0%);
  max-width: calc(17.86 * var(--base-line-height));
}

.c--fcp-change-password-new:focus
  ~ .c--fcp-registration-form--input-container--info
  .c--fcp-registration-form--input-container--info-flyout {
  visibility: visible;
  opacity: 1;
}

.frc__change-password--input-wrapper {
  position: relative;
}

.frc__change-password .c--fcp-registration-form--input-container--info {
  position: static;
}
