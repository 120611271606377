.frc__button--delete,
.frc__button--delete--darker {
  border: 1px solid var(--color-secondary--grey-2);
  border-radius: calc(0.2 * var(--base-line-height));
  color: var(--color-secondary--grey-2);
  cursor: pointer;
  /* FIXME: REMOVE COMMENTED LINES */
  /* line-height: calc(1.5 * var(--base-line-height));
  padding: 0 calc(0.5 * var(--base-line-height)); */
  line-height: calc(1.1 * var(--base-line-height));
  padding: calc(0.35 * var(--base-line-height)) calc(0.5 * var(--base-line-height)) calc(0.2 * var(--base-line-height))
    calc(0.5 * var(--base-line-height));
  position: absolute;
  right: calc(0.5 * var(--base-line-height));
  transition: all 0.2s;
  background: none;
}

.frc__button--delete--small {
  border: 1px solid var(--color-secondary--grey-3);
  border-radius: calc(0.2 * var(--base-line-height));
  color: var(--color-secondary--grey-3);
  cursor: pointer;
  line-height: calc(1.25 * var(--base-line-height));
  padding: 0 calc(0.25 * var(--base-line-height));
  position: absolute;
  right: calc(0.25 * var(--base-line-height));
  transition: all 0.2s;
  background: none;
  top: calc(0.25 * var(--base-line-height));
}

.frc__button--delete--darker {
  color: var(--color-secondary--grey-1);
  border: 1px solid var(--color-secondary--grey-1);
}

.frc__button--delete:hover,
.frc__button--delete--darker:hover {
  border: 1px solid var(--color-primary--DHL-Red);
  color: var(--color-primary--DHL-Red);
}

.frc__button--delete--small:hover {
  border: 1px solid var(--color-secondary--grey-4);
  color: var(--color-secondary--grey-4);
}
