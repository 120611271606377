.frc__enter-shipment-details-rows--wrapper {
  position: relative;
}
.frc__enter-shipment-details-rows--wrapper-fixed-height {
  overflow: auto;
  height: calc(45 * var(--base-line-height));
}

@media screen and (min-width: 768px), print {
  .frc__enter-shipment-details--wrapper .frc__add-button {
    margin-bottom: 0;
  }
}

.has-tooltip-label {
  font-size: calc(1.2 * var(--base-line-height));
  position: relative;
}

.frc__tooltip_title {
  position: absolute;
  margin-top: calc(-0.4 * var(--base-line-height));
  margin-left: calc(0.7 * var(--base-line-height));
}
