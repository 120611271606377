@media (min-width: 1024px) {
  td {
    min-width: calc(13 * var(--base-line-height));
  }
  td:last-child {
    width: calc(30 * var(--base-line-height));
  }
}

@media (max-width: 1023px) {
  td {
    min-width: calc(8 * var(--base-line-height));
  }
}

@media (max-width: 480px) {
  .frc__fixed-first-column {
    margin-left: calc(10 * var(--base-line-height));
  }

  .frc__cell-fixed {
    width: calc(10 * var(--base-line-height));
  }
}
