@media (min-width: 1024px) {
  .frc__saved-shipments td {
    min-width: calc(9.2 * var(--base-line-height));
  }
}

@media (max-width: 1023px) {
  .frc__saved-shipments td {
    min-width: calc(9 * var(--base-line-height));
  }
}

.frc__saved-shipments td:last-child {
  min-width: calc(13 * var(--base-line-height));
  width: calc(13 * var(--base-line-height));
}

.frc__saved-shipments th:nth-child(2),
.frc__saved-shipments th:nth-child(6) {
  padding-right: calc(2 * var(--base-line-height));
}
