@media screen and (min-width: 480px) and (max-width: 665px) {
  .frc__paying-account-number {
    flex-wrap: nowrap;
  }
}

@media screen and (min-width: 480px) {
  .frc__paying-account-number--checkbox {
    margin-top: calc(1.5 * var(--base-line-height));
    margin-left: var(--base-line-height);
  }
}
