.frc__as-options--wrapper {
  background: var(--color-primary--Web-White);
  padding: calc(0.9 * var(--base-line-height)) calc(0.9 * var(--base-line-height)) calc(0.7 * var(--base-line-height))
    calc(0.9 * var(--base-line-height));
  margin-bottom: calc(0.5 * var(--base-line-height));
  flex-basis: auto;
}

.frc__as-option--wrapper {
  margin-bottom: calc(0.7 * var(--base-line-height));
}

.frc__as-options--wrapper .frc__as-option--wrapper:last-child,
.frc__as-options--wrapper .frc__generic-row--wrapper:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.frc__as-options--wrapper .c-form-step--item.c-freightcalc--quotation-form-checkbox-input {
  margin-right: calc(1.4 * var(--base-line-height));
}

.frc__datepicker {
  padding-right: calc(0.7 * var(--base-line-height));
  padding-bottom: calc(0.7 * var(--base-line-height));
}
