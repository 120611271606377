.c-product-width {
  margin: 0.2%;
}

.fcp__select-product-disabled {
  background-color: var(--color-secondary--grey-2);
  padding: 1.4rem 0;
  align-items: center;
  margin-top: auto;
}

.c-freightcalc--pricelist-checkbox.c-freightcalc--pricelist-checkbox--disabled {
  background-color: var(--color-secondary--grey-2);
}

.fcp__is-selected {
  background-color: var(--color-secondary--grey-3);
  border: 1px solid var(--color-primary--DHL-Red);
  min-height: 100%;
}

.frc__selectservice-li {
  list-style-type: disc;
}

.frc__selectservice-li:hover {
  background-color: transparent;
}
