.frc__generic-section--wrapper {
  padding: calc(1.5 * var(--base-line-height));
  /* margin-bottom: 1rem; */
  background-color: var(--color-secondary--grey-5);
}

.frc__p {
  margin-bottom: 0;
}

.frc__generic--field-wrapper,
.frc__generic--field-wrapper--no-top {
  padding-top: var(--base-line-height);
  padding-left: calc(0.3 * var(--base-line-height));
  padding-right: calc(0.3 * var(--base-line-height));
}

.frc__generic--field-wrapper--no-top {
  padding-top: 0;
}

.frc__generic-button {
  line-height: var(--base-line-height);
}

.frc__generic-button.disabled {
  background-color: var(--color-secondary--grey-3);
  border: 2px solid var(--color-primary--Web-White);
  cursor: not-allowed;
}

.frc__generic-button.disabled:hover span {
  color: var(--color-primary--Web-White);
}

.frc__generic-button.base-button--white.disabled {
  border: 2px solid var(--color-primary--DHL-Red);
}

.frc__generic-button.base-button--white.disabled:hover span {
  color: var(--color-primary--DHL-Red);
}

.frc__generic-button.base-button span {
  white-space: normal;
}

.frc__button-icon--align::before {
  padding-right: calc(0.5 * var(--base-line-height));
}

.base-button.frc__base-button--input {
  min-height: calc(2.51 * var(--base-line-height));
  top: calc(0.1 * var(--base-line-height));
  margin-bottom: 0;
}

.frc__generic--list {
  padding: 0 calc(2 * var(--base-line-height));
}

.frc__generic--list.list {
  margin-bottom: 0;
}

.frc__generic--alert,
.frc__generic--done {
  background-color: var(--color-primary--Postyellow);
  color: var(--color-primary--Web-Black);
  padding: calc(0.5 * var(--base-line-height));
  margin-bottom: calc(0.2 * var(--base-line-height));
}

.frc__generic--done {
  background-color: var(--color-secondary--grey-4);
  color: var(--color-secondary--grey-2);
}

.frc__generic--margin-top-0 {
  margin-top: calc(0.6 * var(--base-line-height));
}
.frc-label {
  display: block;
}

.frc-has-loader-icon::before {
  background-color: rgba(255, 255, 255, 0.7);
  background-image: url('../../css/images/loader_tt.svg');
  background-repeat: no-repeat;
  background-position: center;
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
}
