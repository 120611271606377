/* address book */

.c-calculator--tabcontent {
  background: none;
}

.l-invisible {
  visibility: hidden;
}

.l-grid-column {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  flex-wrap: wrap;
}

.l-grid--w-30pc-s {
  flex-basis: 30%;
  max-width: 30%;
  width: 30%;
}

.l-grid--w-70pc-s {
  flex-basis: 70%;
  max-width: 70%;
  width: 70%;
}

.l-grid--w-45pc-s {
  flex-basis: 45%;
  max-width: 45%;
  width: 45%;
}

.l-grid--w-15pc-s {
  flex-basis: 15%;
  max-width: 15%;
  width: 15%;
}

.l-grid--w-5pc-s {
  flex-basis: 5%;
  max-width: 5%;
  width: 5%;
}

@media screen and (max-width: 768px), print {
  .l-grid--w-30pc-s-only {
    flex-basis: 30%;
    max-width: 30%;
    width: 30%;
  }

  .l-grid--w-15pc-s-only {
    flex-basis: 15%;
    max-width: 15%;
    width: 15%;
  }

  .l-grid--w-100pc-s-all {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (min-width: 768px), print {
  .l-grid--w-48pc-m {
    flex-basis: 48%;
    max-width: 48%;
    width: 48%;
  }

  .l-grid--w-15pc-m {
    flex-basis: 15%;
    max-width: 15%;
    width: 15%;
  }

  .l-grid--w-25pc-m {
    flex-basis: 25%;
    max-width: 25%;
    width: 25%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px), print {
  .l-grid--w-15pc-m-s {
    flex-basis: 15%;
    max-width: 15%;
    width: 15%;
  }

  .l-grid--w-30pc-m-s {
    flex-basis: 30%;
    max-width: 30%;
    width: 30%;
  }

  .frc__checkbox--vertical .c-form--element-checkbox ~ .c-form--element-label {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 1024px), print {
  .l-grid--w-15pc-m-m {
    flex-basis: 15%;
    max-width: 15%;
    width: 15%;
  }

  .l-grid--w-88pc-m-m {
    flex-basis: 8.8%;
    max-width: 8.8%;
    width: 8.8%;
  }
}

.has-top-margin-1 {
  margin-top: var(--base-line-height);
}

.has-top-margin-2 {
  margin-top: calc(2 * var(--base-line-height));
}

.has-no-margin-bottom {
  margin-bottom: 0;
}

.margin-bottom-0 {
  margin-bottom: calc(0.5 * var(--base-line-height));
}

.margin-bottom-1 {
  margin-bottom: var(--base-line-height);
}

.margin-bottom-2 {
  margin-bottom: calc(2 * var(--base-line-height));
}

.has-right-margin-1 {
  margin-right: var(--base-line-height);
}

.base-button--frame span {
  color: var(--color-secondary--grey-1);
}
.base-button.disabled {
  cursor: not-allowed;
}

.base-button--frame {
  border: 2px solid var(--color-secondary--grey-1);
  background-color: var(--color-secondary--grey-2);
  background: none;
  will-change: border-color;
  transition: border 200ms;
}

.base-button.hidden {
  display: none;
}

.is-loading,
.is-loading--right {
  height: calc(4 * var(--base-line-height));
  background-image: url('./images/loader_tt.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.is-loading--right {
  background-position: right;
  height: auto;
}

.mini-loading {
  min-width: calc(3 * var(--base-line-height));
  background-size: contain;
  background-position: center top;
}

/* ul.list li::before {
  content: "\e648";
} */

.c-form--element-base {
  line-height: calc(1.3 * var(--base-line-height));
}

.c-form--element-base.c-form--element-select {
  /* padding: 1.4rem 3.5rem 1.4rem 1.2rem; */
  padding: calc(1 * var(--base-line-height)) calc(2 * var(--base-line-height)) calc(1 * var(--base-line-height))
    calc(0.85 * var(--base-line-height));
}

.c-form--element-checkbox ~ .c-form--element-label.c-fcp--checkbox-label {
  padding: calc(0.428 * var(--base-line-height)) 0 calc(0.64 * var(--base-line-height))
    calc(2.5 * var(--base-line-height));
}

@media screen and (min-width: 768px), print {
  .frc__checkbox--vertical .c-form--element-checkbox ~ .c-form--element-label.c-fcp--checkbox-label {
    padding: 0 0 calc(0.64 * var(--base-line-height)) calc(1.3 * var(--base-line-height));
  }
}

.c-form--element-label-checkbox:after,
.c-form--element-label-checkbox:before,
.c-form--element-label-radio:after,
.c-form--element-label-radio:before {
  top: 0;
}

.c-form--element-input:disabled,
.c-form--element-textarea:disabled,
.c-form--element-select:disabled,
.c-form--element-checkbox:disabled ~ .c-form--element-label-checkbox:before,
.c-form--element-radio:disabled ~ .c-form--element-label-radio:before {
  background-color: var(--color-secondary--grey-3);
}

.frc__generic-section--wrapper .c-form--element-textarea {
  padding-top: calc(0.8 * var(--base-line-height));
}

.color-DHL-red {
  color: #d40511;
}

.frc__tooltip-container {
  display: inline;
  position: relative;
  overflow: visible;
}

.c-fcp-quote--info-container:hover .frc__tooltip-container-flyout {
  visibility: visible;
  opacity: 1;
}

.frc__tooltip-container::before {
  position: absolute;
  margin: 0px 11.2px;
  margin: 0 0.7rem;
}

.frc__tooltip-container-flyout {
  display: inline;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;
  z-index: 100;
  bottom: 100%;
  visibility: hidden;
  min-width: 24rem;
  padding: 0.994rem;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  opacity: 0;
  color: #ffffff;
  border-radius: 0.296rem;
  background-color: #666666;
  box-shadow: 0 1px 0.28rem rgba(0, 0, 0, 0.2);
  line-height: 1;
  white-space: normal;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-grid--w-50pc-m-s {
    flex-basis: 50%;
    max-width: 50%;
    width: 50%;
  }
}

@media screen and (max-width: 767px), print {
  .frc__tooltip-container-flyout {
    left: 0;
    width: 100%;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .frc__tooltip-container {
    position: static;
  }
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .l-grid--w-100pc-s-s {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }
}

.base-button--pull-right {
  float: right;
}

.frc__dialog {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
}

.frc__dialog-overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0 auto;
}

.frc__dialog-wrapper {
  top: 0;
  max-height: 92vh;
  margin: 0 auto;
  margin-top: calc(2 * var(--base-line-height));
}

.frc__dialog-container {
  background-color: var(--color-primary--Web-White);
  padding: calc(2.5 * var(--base-line-height));
  border-radius: 0.296rem;
  position: relative;
  margin: 0 auto;
  max-height: inherit;
  overflow-y: auto;
}

.frc__dialog-content {
  max-height: 80vh;
  overflow-y: auto;
}

#frc_dialog-close-btn {
  overflow: unset;
}

.frc__dialog-close-button {
  position: absolute;
  top: calc(0.5 * var(--base-line-height));
  right: 1px;
  transform: scale(0.8);
}

.frc__api-response {
  color: var(--color-primary--Web-White);
  padding: 2rem;
  margin-bottom: 1rem;
}

.frc__api-response h2 {
  margin: 0;
}

.bgcolor--green {
  background: var(--color-secondary--green-1);
}

.bgcolor--red {
  background: var(--color-primary--DHL-Red);
}

.bgcolor--yellow {
  background: var(--color-primary--Postyellow);
}

@media screen and (min-width: 1024px), print {
  .l-grid--w-55pc-m {
    flex-basis: 55%;
    max-width: 55%;
    width: 55%;
  }
  .l-grid--w-45pc-m {
    flex-basis: 45%;
    max-width: 45%;
    width: 45%;
  }
}
