.marketing-banner {
  margin: 10px 0;
}
.marketing-banner.marketing-image {
  min-height: 434px;
  padding: 20px;
  background-repeat: no-repeat;
}
.marketing-banner.marketing-image.marketing-01 {
  background-image: url('img/marketing-01.jpg');
}
.marketing-banner.marketing-image.marketing-02 {
  background-image: url('img/marketing-02.jpg');
}
.marketing-banner.marketing-image.marketing-03 {
  background-image: url('img/marketing-03.jpg');
}
.marketing-banner.marketing-image.marketing-04 {
  background-image: url('img/marketing-04.jpg');
}
.marketing-banner.marketing-image.marketing-05 {
  background-image: url('img/marketing-05.jpg');
}
.marketing-banner a {
  align-items: center;
  background-color: #d40511;
  border: 0.1rem solid #d40511;
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 700;
  justify-content: center;
  letter-spacing: normal;
  margin: 0 0 1.4rem;
  min-height: 4.2rem;
  overflow: hidden;
  padding: 0.7rem 1.54rem;
  position: relative;
  text-align: center;
  vertical-align: middle;
  color: #fff;
}
.marketing-banner a:hover {
  background-color: #f91320;
  border-color: #f91320;
}
