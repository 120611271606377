.fcp__product-width {
  margin: 0.2%;
}
.fcp__select-product-disabled {
  background-color: var(--color-secondary--grey-2);
  padding: 1.4rem 0;
  align-items: center;
  margin-top: auto;
}
.fcp__is-selected {
  background-color: var(--color-secondary--grey-3);
  border: 1px solid var(--color-primary--DHL-Red);
  min-height: 100%;
}

.fcp__freightcalc--margin-bottom-0 {
  margin-bottom: 0;
}
