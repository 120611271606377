.frc__display-scroll-parent {
  overflow: auto;
  border: 1px solid var(--color-secondary--grey-3);
  height: inherit;
  width: inherit;
  white-space: normal;
  background: var(--color-primary--Web-White);
  border-radius: 1.5%;
  -webkit-overflow-scrolling: 'inherit';
}

.frc__display-scroll-parent::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frc__display-scroll-parent::-webkit-scrollbar:vertical {
  width: calc(0.8 * var(--base-line-height));
}

.frc__display-scroll-parent::-webkit-scrollbar:horizontal {
  height: calc(1 * var(--base-line-height));
}

.frc__display-scroll-parent::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 2px solid var(--color-primary--Web-White);
  background-color: rgba(0, 0, 0, 0.5);
}
