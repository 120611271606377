@media print, screen and (min-width: 1024px) {
  .c-swe-freightcalc--producttable .c-productcontainer--scrollarrow {
    height: calc(100% - 1.54rem);
  }
}
@media print, screen and (min-width: 768px) {
  .c-productcontainer--scrollarrow.c-productcontainer--scrollarrow-left {
    left: 0;
  }
  div[class^='c-product-width  c-product'] {
    display: block;
  }
}

@media print, screen and (min-width: 1024px) {
  .c-swe-freightcalc--producttable .c-productcontainer--scrollarrow {
    top: 0.798rem;
    margin: 0 -0.602rem;
  }
}
@media print, screen and (max-width: 1023px) {
  .fcp__productcontainer--scrollarrow {
    display: none;
  }
}

@media print, screen and (min-width: 1024px) {
  .fcp__productcontainer--scrollarrow {
    position: absolute;
    width: 2.8rem;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out;
    cursor: pointer;
  }
  div.c-displayNone {
    display: none;
  }
  div.c-displayBlock {
    display: block;
  }
}

@media print, screen and (min-width: 1024px) {
  .fcp__productcontainer--scrollarrow-left {
    left: 0;
  }
}
@media print, screen and (min-width: 1024px) {
  .c-productcontainer--scrollarrow.c-productcontainer--scrollarrow-left .c-productcontainer--scrollarrow-icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@media print, screen and (min-width: 768px) {
  .fcp__productcontainer--scrollarrow-icon {
    font-size: 40px;
    color: #d40511;
    position: absolute;
    top: 50%;
    left: -6px;
  }
}

@media print, screen and (min-width: 768px) {
  .fcp__productcontainer--scrollarrow-right {
    right: -1px;
  }
}
