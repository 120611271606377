.frc__tooltip-container {
  display: inline;
  overflow: visible;
}

.frc__info-icon-margin-left {
  margin-left: calc(0.2 * var(--base-line-height));
}

.c-fcp-quote--info-container:hover .frc__tooltip-container-flyout {
  visibility: visible;
  opacity: 1;
}

.frc__label .has-tooltip .frc__tooltip-container {
  position: relative;
  top: calc(-0.2 * var(--base-line-height));
}

.frc__tooltip-container::before {
  position: absolute;
  margin: 0 11.2px;
  margin: 0 0.7rem;
}

.frc__tooltip-container-flyout {
  -webkit-transform: translateX(-50%);
  position: relative;
  z-index: 100;
  visibility: hidden;
  min-width: 24rem;
  padding: 0.994rem;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  opacity: 0;
  color: #ffffff;
  border-radius: 0.296rem;
  background-color: #666666;
  box-shadow: 0 1px 0.28rem rgba(0, 0, 0, 0.2);
  line-height: 1;
  white-space: normal;
  bottom: calc(0.2 * var(--base-line-height));
  transform: translateX(0);
  font-weight: normal;
}

@media (min-width: 1024px) {
  .frc__recent-shipments td {
    min-width: calc(9.5 * var(--base-line-height));
  }
}

@media (max-width: 1023px) {
  .frc__recent-shipments td {
    min-width: calc(9 * var(--base-line-height));
  }
}

.frc__recent-shipments td:last-child {
  min-width: calc(13 * var(--base-line-height));
  width: calc(13 * var(--base-line-height));
}

.frc__detailed-information {
  color: var(--color-secondary--grey-1);
  text-align: left;
  font-style: italic;
  font-size: var(--base-line-height);
}

.c--fcp-dashboard--btn-link {
  width: 100%;
}
.c--fcp-dashboard-component.enable-pointer-events {
  background-color: var(--color-secondary--grey-5);
  padding: 0;
}

frc__recent-shipments. th:nth-child(2) {
  padding-right: calc(2 * var(--base-line-height));
}
