.frc__app-tabs {
  display: flex;
  width: 100%;
}

.frc__app-tab {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  border-left: 1px solid var(--color-secondary--grey-4);
  border-top: 4px solid var(--color-secondary--grey-3);
  text-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  margin-right: 1px;
}

.frc__app-tab:first-child {
  border-left: 1px solid var(--color-secondary--grey-5);
}

.frc__app-tab:hover:not(.frc__app-tab--active) {
  background-color: var(--color-secondary--grey-4);
}

.frc__app-tab--active {
  background-color: var(--color-secondary--grey-5);
  border-top-color: var(--color-primary--DHL-Red);
}

.frc__app-tab--completed .frc__app-tab__title {
  color: var(--color-primary--Web-Black);
}

.frc__app-tab__title {
  color: var(--color-secondary--grey-3);
  cursor: pointer;
  margin: 1.4rem 0;
  width: 100%;
  font-weight: 800;
  font-size: 1.6rem;
  line-height: 2.8rem;
}

.frc__app-tab--active .frc__app-tab__title {
  color: var(--color-primary--DHL-Red);
}

@media print, screen and (max-width: 767px) {
  .frc__hide-summary-panel {
    display: none;
  }
}
