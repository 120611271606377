.frc__address-result {
  list-style-type: none;
}

.frc__address-result:hover {
  text-decoration: none;
  border-radius: 5px;
  background-color: #fff4f1;
}

.frc__address-color {
  color: var(--color-secondary--grey-2);
}

.frc__address-street-building {
  margin-bottom: 0;
  margin-top: calc(-0.7 * var(--base-line-height));
}

.frc__address-bar-loader.is-loading {
  position: relative;
  z-index: 99;
}

.frc__display-scroll {
  position: absolute;
  width: 98%;
  margin-left: calc(0.3 * var(--base-line-height));
  max-height: calc(15 * var(--base-line-height));
  z-index: 100;
  padding-left: 0;
  padding-right: 0;
  overflow: auto;
  border: 1px solid var(--color-secondary--grey-3);
  border-radius: 1.5%;
  background: var(--color-primary--Web-White);
}

.frc__display-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frc__display-scroll::-webkit-scrollbar:vertical {
  width: calc(0.8 * var(--base-line-height));
}

.frc__display-scroll::-webkit-scrollbar:horizontal {
  height: calc(1 * var(--base-line-height));
}

.frc__display-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 2px solid var(--color-primary--Web-White);
  background-color: rgba(0, 0, 0, 0.5);
}

.frc__address-search-padding {
  padding-left: calc(0.5 * var(--base-line-height));
  cursor: default;
}

.frc__eod-text-align {
  text-align: center;
}

@media screen and (max-width: 767px), print {
  .frc__display-scroll {
    width: 99%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .frc__display-scroll {
    width: 97%;
  }
}

ol,
ul {
  margin-bottom: 0;
}
