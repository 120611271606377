.frc__table {
  border-spacing: 0px;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  width: 100%;
}

.frc__check-box-label {
  margin-left: calc(1.9 * var(--base-line-height));
}

.frc__dataTable_container {
  border: 1.5px solid var(--color-secondary--grey-3);
  position: relative;
  max-width: 100vw;
}

.frc__table-content {
  margin-left: calc(2.3 * var(--base-line-height));
  margin-top: calc(0.12 * var(--base-line-height));
}

.frc__sort-icon-color {
  position: absolute;
  color: var(--color-primary--DHL-Red);
  font-weight: bolder;
  padding-left: calc(0.3 * var(--base-line-height));
}

.frc__data-table-check-box-label {
  margin-left: calc(2.5 * var(--base-line-height));
}

.frc__data-cell {
  padding: var(--base-line-height);
  position: relative;
  text-align: left;
  vertical-align: top;
}

.frc__data-cell-border-bottom {
  border-top: 0.1px solid var(--color-secondary--grey-4);
}

@media screen and (max-width: 480px) {
  .frc__fixed-th-first-column {
    background-color: var(--color-secondary--grey-4);
  }
}

@media screen and (min-width: 481px) {
  .frc__cell-fixed {
    border: 0;
    position: absolute;
    top: auto;
    left: -1px;
    width: calc(13 * var(--base-line-height));
    white-space: unset;
    text-align: left;
    backface-visibility: hidden;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    height: calc(3.5 * var(--base-line-height));
  }

  .frc__fixed-first-column {
    overflow-x: auto;
    margin-left: calc(12.93 * var(--base-line-height));
  }

  .frc__fixed-th-first-column {
    top: -1px;
    background-color: var(--color-secondary--grey-3);
    height: calc(3.54 * var(--base-line-height));
  }
}

@media screen and (max-width: 767px) {
  .frc__fixed-th-first-column {
    height: calc(3.56 * var(--base-line-height));
  }
}

.frc__fixed-first-column {
  overflow: auto;
}

tr {
  background: var(--color-primary--Web-White);
}

.frc_table-fixed-column-border {
  border-top: 0.1px solid var(--color-secondary--grey-3);
}

.frc__th-column {
  background-color: var(--color-secondary--grey-4);
  height: calc(3.15 * var(--base-line-height));
  white-space: nowrap;
}

tr:nth-child(even) {
  background-color: #f8f8f8;
}

@media (min-width: 1024px) {
  td {
    min-width: calc(16 * var(--base-line-height));
  }
  td:last-child {
    min-width: calc(18 * var(--base-line-height));
  }
  .frc__dataTable_container {
    border-top: 1px solid var(--color-secondary--grey-3);
  }
}

@media (max-width: 1023px) {
  td {
    min-width: calc(13 * var(--base-line-height));
  }
  td:last-child {
    min-width: calc(18 * var(--base-line-height));
  }
}

.frc__dataTable-no-result {
  margin: calc(1.5 * var(--base-line-height)) auto;
}

.frc__scrollable-table {
  overflow: auto;
  position: static;
}

.frc__scrollable-table .frc__data-cell {
  position: static;
}

.frc__info-icon-margin-left {
  position: absolute;
  margin-left: calc(0.2 * var(--base-line-height));
  width: var(--base-line-height);
  height: var(--base-line-height);
}

.frc__tooltip-container {
  position: absolute;
}

/**IE */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .frc__table-layout {
    table-layout: auto;
  }
}

/**FireFox*/
@-moz-document url-prefix() {
  .frc_table-fixed-column-border {
    margin-top: -0.7px;
  }

  .frc__fixed-th-first-column {
    height: calc(3.57 * var(--base-line-height));
  }
}

.frc__fixed-first-column::-webkit-scrollbar {
  background-color: #f5f5f5;
}

.frc__fixed-first-column::-webkit-scrollbar-thumb {
  background-color: var(--color-secondary--grey-2);
  border-radius: calc(3.57 * var(--base-line-height));
}
