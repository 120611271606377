.frc__radio {
}

.frc__radio-button {
  background: yellow;
}

.frc__radio-label {
  color: #191919;
  margin-right: calc(1.6 * var(--base-line-height));
  cursor: pointer;
}

.frc__radio--wrapper:last-child .frc__radio-label {
  margin-right: 0;
}

.frc__radio--wrapper.disabled:last-child .frc__radio-label {
  color: var(--color-secondary--grey-3);
  font-style: italic;
  cursor: initial;
}

.frc__radio-label:before {
  border: calc(0.1 * var(--base-line-height)) solid var(--color-secondary--grey-3);
  border-radius: 50%;
  content: '';
  display: inline-block;
  height: var(--base-line-height);
  margin-right: calc(0.5 * var(--base-line-height));
  vertical-align: bottom;
  width: var(--base-line-height);
  position: relative;
  top: calc(-0.19 * var(--base-line-height));
}

.frc__radio-button:checked + .frc__radio-label:before {
  border: calc(0.36 * var(--base-line-height)) solid var(--color-primary--Postyellow);
  height: calc(0.55 * var(--base-line-height));
  width: calc(0.55 * var(--base-line-height));
}

.frc__radio--wrapper input {
  display: none;
}

.c-product .frc__radio-label {
  color: var(--color-primary--Web-White);
}
