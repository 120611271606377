a {
  color: var(--color-primary--Web-Black);
}

a:hover {
  color: var(--color-primary--DHL-Red);
}

.frc__generic-section--wrapper {
  padding: calc(0.8 * var(--base-line-height));
  /* margin-bottom: 1rem; */
  background-color: var(--color-secondary--grey-5);
  /* position: relative; */
}

.frc__generic-section--wrapper-no-top {
  padding: 0 calc(0.8 * var(--base-line-height)) calc(0.8 * var(--base-line-height)) calc(0.8 * var(--base-line-height));
  background-color: var(--color-secondary--grey-5);
}

.frc__generic-row--wrapper {
  padding-bottom: var(--base-line-height);
}

.frc__p {
  margin-bottom: 0;
}

.frc__generic--field-wrapper,
.frc__generic--field-wrapper--no-top {
  padding-top: var(--base-line-height);
  padding-left: calc(0.3 * var(--base-line-height));
  padding-right: calc(0.3 * var(--base-line-height));
}

.frc__generic--field-wrapper--no-top {
  padding-top: 0;
}

.frc__generic-button {
  line-height: var(--base-line-height);
}

.frc__generic-button.disabled {
  background-color: var(--color-secondary--grey-3);
  border: 2px solid var(--color-primary--Web-White);
  cursor: not-allowed;
}

.frc__generic-button.disabled:hover span {
  color: var(--color-primary--Web-White);
}

.frc__generic-button.base-button--white.disabled {
  border: 2px solid var(--color-primary--DHL-Red);
}

.frc__generic-button.base-button--white.disabled:hover span {
  color: var(--color-primary--DHL-Red);
}

.frc__generic-button.base-button span {
  white-space: normal;
  padding-top: calc(0.2 * var(--base-line-height));
  padding-bottom: calc(0.2 * var(--base-line-height));
}

.frc__button-icon--align::before {
  padding-right: calc(0.5 * var(--base-line-height));
  margin-top: calc(-0.1 * var(--base-line-height));
}

.base-button.frc__base-button--input {
  min-height: calc(2.51 * var(--base-line-height));
  top: calc(0.1 * var(--base-line-height));
  margin-bottom: 0;
}

.base-button.disabled {
  cursor: not-allowed;
}

.frc__generic--list {
  padding: 0 calc(2 * var(--base-line-height));
}

.frc__generic--list.list {
  margin-bottom: 0;
}

.frc__generic--alert,
.frc__generic--done {
  background-color: var(--color-primary--Postyellow);
  color: var(--color-primary--Web-Black);
  padding: calc(0.5 * var(--base-line-height));
  margin-bottom: calc(0.2 * var(--base-line-height));
}

.frc__generic--done {
  background-color: var(--color-secondary--grey-4);
  color: var(--color-secondary--grey-2);
}

.frc__generic--margin-top-0 {
  margin-top: calc(0.6 * var(--base-line-height));
}

.frc__generic--background-white {
  background-color: var(--color-primary--Web-White);
}

.frc__generic--loader-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: none;
}

.frc__generic--loader-overlay.is-loading {
  display: block;
  height: 100%;
  z-index: 999;
  background-attachment: fixed;
}

.frc__enter-shipment-details-rows--wrapper .frc__generic--loader-overlay.is-loading {
  background-attachment: initial;
}

.frc__dialog .frc__generic--loader-overlay.is-loading {
  background-attachment: initial;
}

.frc__column-wrapper {
  display: flex;
  flex-direction: column;
}

.frc-thank-you {
  margin: 0 auto;
  max-width: calc(60 * var(--base-line-height));
}

.frc-thank-you__title {
  margin-bottom: calc(0.8 * var(--base-line-height));
  font-weight: normal;
  margin-top: calc(0.8 * var(--base-line-height));
}

.frc-thank-you__icon {
  width: 2rem;
  height: 2rem;
  margin-right: calc(0.8 * var(--base-line-height));
  color: var(--color-secondary--green-2);
}

.frc-thank-you__icon--red {
  color: var(--color-primary--DHL-Red);
  position: relative;
  top: 3px;
}

.frc-thank-you__icon--yellow {
  color: var(--color-primary--Postyellow);
  position: relative;
  top: 3px;
}

.frc__header-label {
  color: var(--color-secondary--grey-1);
}

.frc__city-suggestion {
  color: var(--color-primary--DHL-Red);
}

.frc__city-underline {
  text-decoration: underline;
  cursor: pointer;
}

.frc__chat-with-us {
  font-size: calc(1.1 * var(--base-line-height));
  color: var(--color-primary--DHL-Red);
}

.frc__chat-with-us:hover {
  color: var(--color-primary--Web-Black);
}

.frc__dialog,
.frc__dialog-overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.frc__label {
  position: relative;
}

.frc__label .has-tooltip .frc__tooltip-container {
  position: relative;
  top: calc(-0.2 * var(--base-line-height));
}

.frc-has-loader-icon::before {
  background-color: rgba(255, 255, 255, 0.7);
  background-image: url('../../css/images/loader_tt.svg');
  background-repeat: no-repeat;
  background-position: center;
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
}

.has-tooltip-label {
  font-size: calc(1.2 * var(--base-line-height));
  position: relative;
}

.frc__tooltip_title {
  position: absolute;
  margin-top: calc(-0.4 * var(--base-line-height));
  margin-left: calc(0.7 * var(--base-line-height));
}

.frc__tooltip-title-container {
  display: inline;
  overflow: visible;
  font-size: calc(1.1 * var(--base-line-height));
}
