.frc__contact-details-label {
  padding-top: calc(2 * var(--base-line-height));
  margin-bottom: 0;
}

.frc__address-book-btn {
  display: flex;
  justify-content: flex-end;
}

.frc__company-search-position {
  position: relative;
}

.frc__update-note {
  font-size: var(--font-size-1);
}

.frc__email-checkbox-confirmation {
  position: absolute;
  top: 10px;
  right: 10px;
}
