.frc_success-msg-layer {
  min-width: calc(6 * var(--base-line-height));
  margin-left: calc(-8 * var(--base-line-height));
  background-color: var(--color-primary--Web-Black);
  color: var(--color-primary--Web-White);
  text-align: center;
  border-radius: calc(0.2 * var(--base-line-height));
  padding: calc(0.85 * var(--base-line-height));
  position: fixed;
  z-index: 1003;
  left: 50%;
  bottom: calc(4 * var(--base-line-height));
}

.frc__success-icon {
  font-size: calc(1.1 * var(--base-line-height));
  margin-right: calc(0.7 * var(--base-line-height));
  color: var(--color-secondary--green-1);
}
