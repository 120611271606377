.frc__addresspickup-card {
  border: 1px solid var(--color-secondary--grey-6);
  margin: 1%;
  padding: 1.5rem;
  background-color: var(--color-primary--Web-White);
  border-radius: 0.5rem;
  transition: all ease-in 0.3s;
}

.frc__addresspickup-card:hover {
  cursor: pointer;
  background-color: #fff4f1;
  box-shadow: 2px 2px 2px 0px rgba(150, 150, 150, 1);
}

.frc__address-book-grid .rdt_TableRow {
  background-color: var(--color-secondary--grey-5);
}

.frc__address-book-grid .rdt_TableCell {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 481px) and (max-width: 1023px) {
  .frc__addresspickup-card-width {
    width: 48%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .frc__addresspickup-card-width {
    width: 98%;
  }
}

@media screen and (min-width: 1024px) {
  .frc__addresspickup-card-width {
    width: 31%;
  }
}
