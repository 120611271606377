.frc__forgot-password {
  background-color: var(--color-secondary--grey-5);
  padding: calc(2 * var(--base-line-height));
}

.frc__forgot-password-message--error {
  color: var(--color-primary--DHL-Red);
  font-size: var(--font-size-1);
  margin: 0;
  line-height: calc(1.2 * var(--base-line-height));
}

.frc__forgot-password-message--success {
  color: var(--color-secondary--green-1);
  font-size: var(--font-size-1);
  margin: 0;
}

.frc__forgot-password-submit-item {
  padding-top: var(--base-line-height);
}
