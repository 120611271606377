.c-producttable .visible {
  opacity: 1;
}

.c-swe-freightcalc--producttable .c-producttablecontainer {
  margin: 0;
  padding: 0.994rem;
}

.c-producttablecontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  color: var(--color-secondary--grey-1);
  margin-bottom: 5.6rem;
}

.c-swe-freightcalc--producttable .c-productcontainer {
  width: 100%;
  max-width: inherit;
}

.c-productcontainer {
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.c-swe-freightcalc--producttable .c-productcontainer--inner {
  overflow-x: hidden;
  white-space: normal;
}

@media print, screen and (min-width: 768px) {
  .c-productcontainer--inner {
    display: block;
  }
}

@media print, screen and (min-width: 1024px) {
  .c-productcontainer--inner {
    overflow: auto;
    width: 100%;
    white-space: nowrap;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.c-freightportal--result-container .c-product {
  border: none;
  padding: 0;
  margin: 0 0.5%;
}

.c-product {
  width: 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-right: 1px solid var(--color-primary--Web-White);
  position: relative;
  -webkit-transition:
    width 0.4s ease-in-out,
    opacity 101ms ease-in-out 0.3s;
  transition:
    width 0.4s ease-in-out,
    opacity 101ms ease-in-out 0.3s;
}

.c-teaser-carousel--carousel-item,
.c-teaser-carousel--carousel-item .link {
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.c-teaser-carousel--carousel-item {
  width: 100%;
  margin-bottom: 1.4rem;
}

.c-teaser-carousel--carousel-item {
  width: 100%;
  margin-bottom: 1.4rem;
}

.c-teaser-carousel--carousel-item {
  display: -webkit-box;
  display: flex;
  -webkit-transition: box-shadow 50ms ease-in-out;
  transition: box-shadow 50ms ease-in-out;
}

.c-swe-freightcalc--producttable .c-swe--freightcalc--result-wrapper {
  min-height: 100%;
  background-color: var(--color-primary--Web-White);
}

.c-freightcalc--pricelist-box {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
  height: 100%;
}

.c-swe-freightcalc--pricelist-top {
  border-bottom: none;
  background-color: var(--color-primary--Postyellow);
  padding: 1.4rem 0.7rem;
}

.c-freightcalc--pricelist-amount-display,
.c-freightcalc--radio-options {
  padding: 1.4rem 0;
}

.c-freightcalc--pricelist-delivery-date {
  border: 2px solid var(--color-primary--DHL-Red);
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 8.4rem;
  -ms-flex: 0 0 8.4rem;
  flex: 0 0 8.4rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
  margin-bottom: 1rem;
}

.c-freightcalc--pricelist-seedetails {
  display: none;
  background-color: var(--color-primary--Postyellow);
  cursor: pointer;
  width: 100%;
  border: none;
  outline: none;
  font-size: 2rem;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.c-freightcalc--list {
  padding-left: 2.8rem;
}
.c-freightcalc--pricelist-details-display.c-freightcalc--list {
  padding: 1rem 2rem;
}

.c-freightcalc--pricelist-details-display {
  display: block;
}

.list {
  margin-bottom: 1.4rem;
  list-style-position: outside;
  color: var(--color-primary--DHL-Red);
}

.c-fcp-product-description {
  color: var(--color-primary--Web-Black);
}

.c-teaser-carousel--carousel-item .c-swe-freightcalc--productinfo-link .link {
  display: inline;
}

.c-teaser-carousel--carousel-item .link {
  width: 100%;
  color: var(--color-primary--Web-Black);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.c-freightcalc--pricelist-checkbox {
  background-color: var(--color-primary--DHL-Red);
  padding: 1.4rem 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: auto;
}

.frc__service-radio {
  justify-content: center;
  color: var(--color-primary--Web-White);
}
.c-producttablecontainer.has-pricelist-error {
  border: 1px solid var(--color-primary--DHL-Red);
}
.has-error-message {
  color: var(--color-primary--DHL-Red);
  display: block;
  font-size: 1.4rem;
  margin-bottom: 0.84rem;
}
@media print, screen and (min-width: 1024px) {
  .c-teaser-carousel--carousel-item {
    width: 23%;
  }
}

@media print, screen and (min-width: 1024px) {
  .c-teaser-carousel--carousel-item {
    width: 25%;
  }
}

@media print, screen and (min-width: 1024px) {
  .c-swe-freightcalc--producttable .c-product {
    width: 33%;
    margin-bottom: 0.798rem;
    margin-top: 0.798rem;
  }
}
