.frc__completed_icon {
  color: var(--color-secondary--green-1);
}

.frc__icon-width {
  height: 1em;
  width: calc(1.1 * var(--base-line-height));
}

.frc__icon-has-next {
  width: calc(0.7 * var(--base-line-height));
}

.frc__incomplete_icon {
  color: var(--color-primary--Postyellow);
}

.frc__simplified_icon {
  font-size: 2em;
  width: 0.8em;
}

.frc__icon-display {
  height: calc(2.8 * var(--base-line-height));
  width: calc(2.8 * var(--base-line-height));
}

.frc__action-icon {
  width: calc(0.9 * var(--base-line-height));
  height: calc(1.2 * var(--base-line-height));
  vertical-align: -0.1em;
}

.frc__action-edit-view {
  width: calc(1.2 * var(--base-line-height));
}

.frc__icon-red {
  color: var(--color-primary--DHL-Red);
}
