.frc__login-form--container {
  background: rgba(255, 255, 255, 0.8);
  padding: calc(1.5 * var(--base-line-height));
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 767px), print {
  .frc__login-form--container {
    background-color: var(--color-primary--Web-White);
  }
}

.frc__login-form--title {
  margin-bottom: 0;
}

.frc__freightcalc--quotation-form-checkbox-input {
  margin-top: calc(0.4 * var(--base-line-height));
}

.frc__login-form-element--padding-top {
  padding-top: calc(0.67 * var(--base-line-height));
}

.frc__login-form-message--error {
  color: var(--color-primary--DHL-Red);
  font-size: var(--font-size-1);
  margin: 0;
  line-height: calc(1.2 * var(--base-line-height));
  margin-top: calc(0.2 * var(--base-line-height));
}

.frc__login-form-message--success {
  color: var(--color-secondary--green-1);
  font-size: var(--font-size-1);
  margin: 0;
}

.frc__login-form-button-top {
  margin-bottom: 0;
}

.frc__login-form-button-down {
  margin-top: var(--base-line-height);
}

.frc__login-form-message {
  padding: calc(0.5 * var(--base-line-height)) 0 0 0;
  font-weight: bold;
}

.frc__login-form-noaccount-link {
  line-height: calc(1.5 * var(--base-line-height));
}

@media screen and (min-width: 768px), print {
  .frc__login-form {
    width: calc(27 * var(--base-line-height));
  }
}
