.frc__import-modal-error {
  background-color: var(--color-secondary--grey-5);
  border: 1px solid var(--color-secondary--grey-2);
  height: calc(8 * var(--base-line-height));
  overflow: auto;
  border-radius: calc(0.5 * var(--base-line-height));
  padding: calc(0.5 * var(--base-line-height));
  white-space: pre-wrap;
}

.frc__upload_contact {
  border: 1px solid var(--color-secondary--grey-2);
  font-size: 1.4rem;
  padding: calc(0.67 * var(--base-line-height));
  width: 100%;
}

.frc__upload-doc-margin-right {
  margin-right: calc(0.3 * var(--base-line-height));
}

.frc__modal-import-dialog-width {
  max-width: 65em;
}

.frc__download_link {
  text-decoration: underline;
}

.frc__download_link:hover {
  cursor: pointer;
  color: var(--color-primary--DHL-Red);
}
