.frc__reference-cell {
  color: var(--color-secondary--grey-2);
  font-style: italic;
  font-size: 14px;
}

.frc__custom-cell-line-break {
  display: block;
}

.frc__custom-cell-button {
  padding-top: 25px;
}

.frc-select--wrapper .c-form--element-select {
  padding: calc(1 * var(--base-line-height)) calc(2 * var(--base-line-height)) calc(1 * var(--base-line-height))
    calc(0.85 * var(--base-line-height));
  margin-bottom: calc(0.5 * var(--base-line-height));
}

.frc__operation-custom-cell-expander {
  margin-left: calc(8 * var(--base-line-height));
}

header.rdt_TableHeader {
  display: none;
}

.frc__operation-icon {
  color: var(--color-primary--DHL-Red);
  cursor: pointer;
  margin: calc(0.3 * var(--base-line-height)) calc(0.7 * var(--base-line-height));
}

.frc__operation-icon span {
  padding-left: calc(0.4 * var(--base-line-height));
}

@media (max-width: 480px) {
  .frc__operation-icon-width {
    margin-bottom: calc(0.6 * var(--base-line-height));
  }
}

.frc__summary--row {
  display: flex;
  justify-content: space-between;
}

.frc__summary--row.l-grid--left-s {
  justify-content: flex-start;
}

.frc__summary--row div:last-child {
  font-weight: bold;
  padding-left: calc(1.5 * var(--base-line-height));
}

.frc__summary--row.l-grid--left-s div:last-child {
  padding-left: calc(0.5 * var(--base-line-height));
}

.frc__summary--row div:last-child {
  font-weight: bold;
}

.frc__button-space-divider {
  width: calc(0.8 * var(--base-line-height));
  height: auto;
  display: inline-block;
}

.frc__diable-action-btn {
  color: var(--color-secondary--grey-3);
}
