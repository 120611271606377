.frc__progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  padding: 3px;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.frc__progress-bar-fill {
  display: block;
  height: 22px;
  background-color: var(--color-secondary--green-2);
  border-radius: 3px;
  transition: width 500ms ease-in-out;
}

.frc__completion-text {
  float: right;
  color: var(--color-primary--Web-White);
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .frc__dialog .frc__dialog-wrapper .frc__dialog-container .frc__dhl-red-color {
    padding-top: calc(0.93 * var(--base-line-height));
  }
}
